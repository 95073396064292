import {Auth} from "aws-amplify";
import {TModelKey} from "../model/baseModel";
import {HttpMethod} from "./general-types";

export type TGetItemsUrlParam = {
    parentId: string,
    exclusiveStartKey?: string,
    namePrefix?: string,
    method: HttpMethod
}

export type TGetFetchOptionParam = {
    method?: HttpMethod;
    body?: any
}

export const httpResponseCodeIsSuccess = (httpCode: number): boolean => 200 <= httpCode && httpCode <= 299;

export const getFetchOption = async (param?: TGetFetchOptionParam): Promise<RequestInit> => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const options: RequestInit = {
        method: param?.method ? param?.method : 'GET',
        mode: 'cors',
        headers: {
            Authorization: token,
            'Content-Type': 'application/json'
        },
    };
    if ([ 'POST', 'PATCH', 'PUT' ].includes(param?.method || '')) {
        if (typeof param?.body === 'string') {
            options.body = param.body;
        } else {
            options.body = JSON.stringify(param?.body);
        }
    }
    return options;
};

export const getItemsUrlParameter = (param: TGetItemsUrlParam | TModelKey): string => {
    const urlParams: string[] = [];

    if ( 'method' in param ) {
        if ( param?.method === 'GET') {
            urlParams.push('perPage=15');
            if (param?.exclusiveStartKey) {
                urlParams.push(`exclusiveStartKey=${param.exclusiveStartKey}`);
            }
            if (param?.namePrefix) {
                urlParams.push(`name=${param.namePrefix}`);
            }
        }
    }
    if (urlParams.length > 0) {
        return '?' + urlParams.join('&');
    } else {
        return '';
    }
}

