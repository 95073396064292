export function setJsonEditorRef(ref: any) {
    const setRef = (instance: any) => {
        if (instance) {
            ref.current = instance.jsonEditor;
        } else {
            ref.current = null;
        }
    };
    return setRef;
}
