import React, {ReactNode} from "react";
import {Button, InputLabel, MenuItem, Select, OutlinedInput} from "@mui/material";
import {JsonTemplate} from "../../model/jsonTemplate";
import {SelectChangeEvent} from "@mui/material/Select";
import {Theme, useTheme} from '@mui/material/styles';
import BaseModel from "../../model/baseModel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const getStyles = (name: string|undefined, templates: readonly JsonTemplate[], theme: Theme) => {
    return {
        fontWeight:
            templates.find(t => name && name === t.name)
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export type SelectTemplateProps = {
    label: string,
    templates: JsonTemplate[],
    elementId: string,
    modelId: string|undefined,
    templateId: string|undefined,
    onChangeSelect: (modelId: string|undefined, value: string) => void
}

export const SelectJsonTemplate: React.FC<SelectTemplateProps> = (props) => {
    const theme = useTheme();
    const handleSelectChange = (event: SelectChangeEvent) => {
        props.onChangeSelect(props.modelId, event.target.value);
    };
    return (
        <div>
            <InputLabel id="user-template-json-label">{props.label}</InputLabel>
            <Select
                displayEmpty
                labelId={`${props.elementId}-label`}
                id={props.elementId}
                value={props.templateId}
                onChange={handleSelectChange}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                    const t = selected !== '' ? props.templates.find(t => t.id === selected) : null
                    return t?.name ? t.name : <em>テンプレートを選択してください</em>;
                }}
            >
                {props.templates.map((template) => (
                    <MenuItem value={template.id} style={getStyles(template.name, props.templates, theme)}>
                        {template.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}