import React, {useEffect, useState} from "react";
import {Box, Button, Chip, FormControl, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput} from "@mui/material";
import {FormTextField} from "../../common/FormTextField/index"
import {Dialog} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Theme, useTheme} from '@mui/material/styles';

import {JsonTemplate} from "../../../model/jsonTemplate";
import {SelectJsonTemplate} from "../../elements/SelectJsonTemplate";
import {Admin, TAdmin} from "../../../model/admin";
import {Tenant} from "../../../model/tenant";
import Divider from "@mui/material/Divider";

export interface IAdminFormProps {
    key?: string,
    model?: Admin,
    parentId: string;
    tenants: Tenant[],
    adminTenantNames?: string[],
    isOpen: boolean,
    isFullScreen?: boolean,
    index?: number
    editMode: boolean,
    onUpdateItem: (item: TAdmin) => void,
    onClose: (index: number | undefined) => void
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (id: string, tenants: readonly Tenant[], theme: Theme) => {
    return {
        fontWeight:
            tenants.findIndex(e => id === e.id)
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
};

export const ProviderAdminForm = (props: IAdminFormProps) => {
    const theme = useTheme();
    const [ name, setName ] = useState<string>(props.model?.name || '');
    const [ email, setEmail ] = useState(props.model?.email || '');
    const [ temporaryPassword, setTemporaryPassword ] = useState(props.model?.temporaryPassword || '');
    const [ tenants ] = useState(props.tenants || []);
    const [ adminTenantNames, setAdminTenantNames ] = React.useState<string[]>(props.adminTenantNames || []);
    const [ adminProviderId, ] = React.useState(props.editMode ? props.model?.adminProviderId : props.parentId);
    const handleChange = (event: SelectChangeEvent<typeof adminTenantNames>) => {
        const {
            target: {value},
        } = event;

        setAdminTenantNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Dialog key={props.key} open={props.isOpen} fullScreen={false} onClose={() => props.onClose(props.index)}>
            <DialogTitle sx={{fontWeight: 'bold'}}>{props.editMode ? '管理者編集' : '管理者登録'}</DialogTitle>
            <Divider/>
            <DialogContent>
                <FormTextField id={"name"} label={"管理者名"}
                               value={name} setProps={setName} autofocus={true}/>
                <FormTextField id={"email"} label={"email"}
                               value={email} setProps={setEmail}/>
                {/*<FormTextField id={"temporaryPassword"} label={"temporaryPassword"}*/}
                {/*               value={temporaryPassword} setProps={setTemporaryPassword}/>*/}
                {/*<InputLabel id="form-select-label">管理テナント</InputLabel>*/}
                <FormLabel sx={{m: 1}}>所属</FormLabel>
                <Grid container spacing={2}>
                    <Grid xs={12} sx={{p: 2}}>
                        <Select
                            labelId="adminTenantsUuid-label"
                            id="adminTenantsUuid"
                            // multiple
                            value={adminTenantNames}
                            onChange={handleChange}
                            input={<OutlinedInput id="adminTenantsUuid-chip" label="Chip"/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {tenants.map((t) => (
                                <MenuItem
                                    key={t.id}
                                    value={t.name}
                                    style={getStyles(t.name || '', tenants, theme)}
                                >
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button className="App-Form-Button" onClick={() => {
                    const adminTenantIds: string[] =
                        adminTenantNames.map(name => {
                            const tenant = tenants.find(ts => ts.name === name);
                            return tenant?.id
                        }).filter((id): id is Exclude<typeof id, undefined> => id !== undefined)

                    const admin: TAdmin = {
                        parentId: adminProviderId,
                        id: props.model?.id || '',
                        name: name,
                        email: email,
                        adminTenantIds,
                        temporaryPassword: temporaryPassword,
                        adminProviderId: adminProviderId
                    };
                    console.log('ProviderAdminForm onUpdateItem:', admin)
                    props.onUpdateItem(admin);
                    props.onClose(props.index);
                }}>
                    <SaveIcon/> {props.editMode ? "更新" : "新規登録"}
                </Button>
                <Button className="App-Form-Button" onClick={() => {
                    props.onClose(props.index);
                }}><CloseIcon/>Close</Button>
            </DialogActions>
        </Dialog>
    )
};
