import React, {useEffect, useRef, useState} from "react";
import GenericTemplate from "../../templates/GenericTemplate";
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography} from '@mui/material';
import {Edit, Delete, Create, Info} from '@mui/icons-material';
import {Button} from "@mui/material";
import {Box, IconButton} from '@mui/material';

import {useParams} from "react-router-dom";
import {usePagination} from "../../../hooks/usePagination";
import {toast} from 'react-toastify';
import {IDownloadFile, DownloadFile} from "../../../model/downloadFile";
import {IModelKey} from "../../../model/baseModel";
import {ItemsPagination} from "../../common/ItemsPagenation";
import {DownloadFileForm} from "./DownloadFileForm";
import {Progress} from "../../common/Progress";
import {getTenantById} from "../../../modules/acmv3-api/tenants";
import {Tenant} from "../../../model/tenant";
import {deleteDownloadFile, getDownloadFilesUri, getPutDownloadFileUrl,} from "../../../modules/acmv3-api/downloadFile";

export const DownloadFilesPage: React.FC = () => {
    const handleOpen = (index: number) => setEditDialogOpen(index, true);
    const handleClose = (index: number) => setEditDialogOpen(index, false);
    const [ isOpenNewDialog, setIsOpenNewDialog ] = useState(false);
    const [ isOpenEditDialog, setIsOpenEditDialog ] = useState<boolean[]>([])
    const [ tenantId, ] = useState<string>(useParams().parentId || '');
    const [ tenant, setTenant ] = useState<Tenant>();
    const [ fetchUri, setFetchUri ] = useState("");
    const [ searchKeyword, setSearchKeyword ] = useState("");
    const [ currentPageItems, setCurrentPageItems ] = useState<DownloadFile[]>([])
    const openId = useRef<number>(1);

    const setEditDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpenEditDialog(opens);
    };

    const setNewUri = () => {
        const uri = getDownloadFilesUri({
            parentId: tenantId,
            exclusiveStartKey: keys[currentPageIndex], namePrefix: (searchKeyword || '').toLowerCase(),
            method: 'GET'
        });
        setFetchUri(uri);
    };

    const {
        isLoading, currentPageIndex, setPagerNext, setPagerPrevious, setPagerChange, totalPages, keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: (e) => {
            toast.error(`読み込みに失敗しました + ${e.toString()}`);
        },
        onNewUri: setNewUri
    })

    useEffect(() => {
        setNewUri();
    }, []);

    useEffect(() => {
        getTenantById(tenantId).then(t => {
            setTenant(t);
        })
    }, [ tenantId ]);

    useEffect(() => {
        setIsOpenEditDialog(new Array(currentPageItems.length))
    }, [ currentPageItems ]);

    const onCreateItem = (newItem: IDownloadFile, file: File) => {
        if (!file) {
            return;
        }
        const param = {fileName: file.name, contentType: file.type}
        getPutDownloadFileUrl(tenantId, param).then(r => {
            if (r.body.preSignedUrl) {
                const options = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': file.type
                    },
                    body: file
                };
                return fetch(r.body.preSignedUrl, options)
            } else {
                toast.error(`アップロードに失敗しました(${r.fetchResult.status})`);
                throw new Error('Error preSignedUrl')
            }
        }).then(r => {
            if (!r?.ok) {
                throw new Error(`${r?.status}: ${r?.statusText}`);
            }
            setCurrentPageItems([
                ...currentPageItems,
                new DownloadFile(newItem)
            ]);
            toast.success('新規登録に成功しました');
        }).catch((error) => {
            console.log("catch(1) : " + error);
            return Promise.reject("CATCH(1)");
        });
    }

    const onRemoveItem = (fileName: string) => {
        deleteDownloadFile(tenantId, fileName).then(r => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(item => item.name !== fileName));
        }).catch(e => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    const setClose = () => setIsOpenNewDialog(false);

    if (isLoading) return (<Progress/>)
    return (
        <GenericTemplate title="ファイル">
            <Typography variant="h5" sx={{m: "1rem"}}>
                ファイル一覧
            </Typography>
            <TableContainer component={Paper}>

                <div className="App-Content-Menu">
                    <Box>
                        <Button onClick={() => {
                            setIsOpenNewDialog(true);
                            openId.current = openId.current + 1;
                        }}><Create/>New</Button>
                    </Box>
                </div>

                <DownloadFileForm
                    key={openId.current.toString()}
                    isOpen={isOpenNewDialog}
                    index={0}
                    onClose={setClose}
                    onUpdateItem={onCreateItem}
                    editMode={false}
                    model={new DownloadFile({name: '', contentType: ''})}
                />

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ファイル名</TableCell>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageItems.map((item, index) => (
                            <TableRow key={item.name}>
                                <TableCell component="th" scope="row">
                                    {item.name}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => {
                                        onRemoveItem(item.name)
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    );
};
