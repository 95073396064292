import React, {useState, useEffect, useMemo} from "react";
import {getFetchOption} from "../modules/acmv3-api";

// Page number is 1 start.
export const getIndexFromPageNo = pageNo => pageNo - 1;
export const getPageNoFromIndex = pageNo => pageNo + 1;

export function usePagination({
                                  fetchUri,
                                  setCurrentPageItems = f => f,
                                  onError = f => f,
                                  onNewUri = f => f,
                              }) {

    const [isLoading, setIsLoading] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [keys, setKeys] = useState([undefined]);
    const nextPageIndex = () => currentPageIndex + 1;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const fetchOption = await getFetchOption();
                if (!(fetchUri && fetchOption)) return;
                const response = await fetch(fetchUri, fetchOption);
                if (!(200 <= response.status && response.status <= 299)) {
                    throw new Error(`Fetch failed. (${response.status}: ${response.statusText})`)
                }
                const json = await response.json();
                setCurrentPageItems(json.Items);
                if (json.LastEvaluatedKey) {
                    if (!Object.keys(keys).includes(nextPageIndex().toString())) {
                        setKeys({...keys, ...{[nextPageIndex()]: json.LastEvaluatedKey}});
                        setTotalPages(totalPages + 1);
                    }
                }
            } catch (e) {
                onError(e);
            } finally {
                setIsLoading(false)
            }
        })()
    }, [fetchUri]);

    useEffect(() => {
        onNewUri();
    }, [currentPageIndex]);

    const setPagerNext = () => {
        setCurrentPageIndex(currentPageIndex + 1);
    }
    const setPagerPrevious = () => {
        setCurrentPageIndex(currentPageIndex - 1);
    }
    const setPagerChange = (newPageNo) => {
        setCurrentPageIndex(newPageNo - 1)
    }

    const pageActionAfterRemoveItem = (currentPageItemCount) => {
        if (currentPageItemCount <= 1) {
            if (0 < currentPageIndex) {
                setPagerPrevious();
            } else {
                window.location.reload();
            }
        }
    }

    return {
        isLoading,
        currentPageIndex,
        setCurrentPageIndex,
        totalPages,
        keys,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem,
    };
}