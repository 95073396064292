import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";

type TProviderAttributes = {
    name?: string,
    maxTenant?: number,
    allowTenantMaxUser?: number,
    allowTenantIdPrefix?: string,
    allowTenantIdRegex?: string
}

export type TProvider = TBaseModel & TProviderAttributes;
export type TProviderParam = TBaseModelParam & TProviderAttributes

export class Provider extends BaseModel {
    name?: string;
    maxTenant?: number;
    allowTenantMaxUser?: number;
    allowTenantIdPrefix?: string;
    allowTenantIdRegex?: string;

    constructor(obj: TProviderParam) {
        super(obj);
        this.name = obj.name;
        this.setAttributes(obj);
    }

    setAttributes(obj: TProviderParam) {
        this.updateAttributes(obj)
    }

    updateAttributes(obj: TProviderParam) {
        super.updateAttribute(obj, 'name');
        super.updateAttribute(obj, 'maxTenant');
        super.updateAttribute(obj, 'allowTenantMaxUser');
        super.updateAttribute(obj, 'allowTenantIdPrefix');
        super.updateAttribute(obj, 'allowTenantIdRegex');
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            name: this.name,
            maxTenant: this.maxTenant,
            allowTenantMaxUser: this.allowTenantMaxUser,
            allowTenantIdPrefix: this.allowTenantIdPrefix,
            allowTenantIdRegex: this.allowTenantIdRegex,
            updatedAt: this.updatedAt
        }
    }
}


