export const normalizedId = (id: string|undefined) => {
    if ( typeof id === 'string') {
        const str = id.replace(/.*\./, '');
        return str.length < 10 ? str : str.substring(0, 10) + '...'
    }
    return '';
};

export const normalizedText = (str: string|undefined, maxLen: number = 32) => {
    if ( typeof str === 'string') {
        return str.length < maxLen ? str : str.substring(0, maxLen) + '...'
    }
    return '';
};
