import React, {useEffect, useRef, useState} from "react";
import GenericTemplate from "../../templates/GenericTemplate";
import {
    Backdrop,
    Box,
    Button, CircularProgress,
    Checkbox,
    IconButton, LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {Create, Delete, Edit, QrCode2} from '@mui/icons-material';

import {deleteUser, getUsersUri, postUser, putUser, userQrDownload} from "../../../modules/acmv3-api/user";
import {useParams} from "react-router-dom";
import {usePagination} from "../../../hooks/usePagination";
import {toast} from 'react-toastify';
import {TUser, User} from "../../../model/user";
import {TModelKey} from "../../../model/baseModel";
import {ItemsPagination} from "../../common/ItemsPagenation";
import {UserForm} from "./UserForm";
import {getJsonTemplateById} from "../../../modules/acmv3-api/jsonTemplate";
import {getTenantById} from "../../../modules/acmv3-api/tenants"
import {Tenant} from "../../../model/tenant";
import {JsonTemplate} from "../../../model/jsonTemplate";
import {normalizedId} from "../helper/formHelper";
import {generateFetchErrorMessage} from "../../../modules/error-message";
import {UserQrDialog, UserQrDialogCallbackParam} from "./UserQrDialog";
import {UserQrResultDialog} from "./UserQrResultDialog";

export const UsersPage: React.FC = () => {
    const handleOpen = (index: number) => setEditDialogOpen(index, true);
    const handleClose = (index: number) => setEditDialogOpen(index, false);
    const [ isOpenNewDialog, setIsOpenNewDialog ] = useState(false);
    const [ isOpenEditDialog, setIsOpenEditDialog ] = useState<boolean[]>([])
    const [ tenantId, ] = useState<string>(useParams().parentId || '');
    const [ tenant, setTenant ] = useState<Tenant>();
    const [ jsonTemplate, setJsonTemplate ] = useState<JsonTemplate>();
    const [ fetchUri, setFetchUri ] = useState("");
    const [ searchKeyword,  ] = useState("");
    const [ currentPageItems, setCurrentPageItems ] = useState<User[]>([])
    const openId = useRef<number>(1);
    const [ isProgress, setIsProgress ] = React.useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ checkedUsers, setCheckedUsers ] = useState<string[]>([]);
    const [ userQrDialogIsOpen, setUserQrDialogIsOpen ] = useState(false);
    const [ userQrResultDialogIsOpen, setUserQrResultDialogIsOpen ] = useState(false);
    const [ qrFileUrl, setQrFileUrl ] = useState('');
    const [ checkedHeaderCheckbox, setCheckedHeaderCheckbox ] = useState(false);

    const setEditDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpenEditDialog(opens);
    };

    const setNewUri = () => {
        const uri = getUsersUri({
            parentId: tenantId,
            exclusiveStartKey: keys[currentPageIndex], namePrefix: (searchKeyword || '').toLowerCase(),
            method: 'GET'
        });
        setFetchUri(uri);
    };

    const {
        currentPageIndex, setPagerNext, setPagerPrevious, setPagerChange, totalPages, keys,
        pageActionAfterRemoveItem
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: (e) => {
            toast.error(`読み込みに失敗しました ${e.toString()}`);
        },
        onNewUri: setNewUri
    })

    useEffect(() => {
        setNewUri();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getTenantById(tenantId).then(t => {
            setTenant(t);
            return t.userUiSchemaId ?
                getJsonTemplateById(t.userUiSchemaId) : undefined;
        }).then(j => {
            setJsonTemplate(j)
        }).finally(()=>setIsLoading(false))
    }, [ tenantId ]);

    useEffect(() => {
        setIsOpenEditDialog(new Array(currentPageItems.length))
    }, [ currentPageItems ]);

    useEffect(() => {
        if (checkedHeaderCheckbox) {
            setCheckedUsers(currentPageItems.map(item => (item && item.id) || '').filter(item => item));
        } else {
            setCheckedUsers([]);
        }
    }, [ checkedHeaderCheckbox,currentPageItems ]);

    const onCreateItem = (newData: TUser) => {
        setIsProgress(true);
        postUser({parentId: tenantId, item: newData}).then(({body, fetchResult}) => {
            if (fetchResult?.ok) {
                toast.success('新規登録に成功しました');
                const newItems = [
                    ...currentPageItems,
                    new User(body)
                ];
                setCurrentPageItems(newItems);
            } else {
                toast.error(`新規登録に失敗しました(${generateFetchErrorMessage(body)})`);
            }
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        }).finally(() => {
            setIsProgress(false);
        });
    }

    const onUpdateItem = (newItem: TUser) => {
        setIsProgress(true);
        putUser(newItem).then(({body, fetchResult}) => {
            if (fetchResult.ok) {
                toast.success('更新に成功しました');
                setCurrentPageItems(currentPageItems.map((item: User) => {
                        return item.parentId === newItem.parentId && item.id === newItem.id ?
                            new User(newItem) : item
                    }
                ));
            } else {
                toast.error(`更新に失敗しました ${body.toString()}`);
            }
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        }).finally(() => {
            setIsProgress(false);
        });
        const newItems = currentPageItems.map(item => item.id === newItem.id ? new User(newItem) : item);
        setCurrentPageItems(newItems);
    }

    const onRemoveItem = (key: TModelKey) => {
        setIsProgress(true);
        deleteUser(key).then(r => {
            setCurrentPageItems(currentPageItems.filter(item => item.id !== key.id));
            toast.success('削除に成功しました');
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch(e => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        }).finally(() => {
            setIsProgress(false);
        })
    }

    const setClose = () => setIsOpenNewDialog(false);
    const onChangeCheckBox = (id?: string) => {
        if (id) {
            if (checkedUsers.includes(id)) {
                setCheckedUsers(checkedUsers.filter(itemId => itemId !== id));
            } else {
                setCheckedUsers([ ...checkedUsers, id ]);
            }
        }
    };
    const setUserQrDialogSetIsOpen = (open: boolean) => {
        setUserQrDialogIsOpen(open)
    };

    const onCreateQrButton = (param: UserQrDialogCallbackParam) => {
        setIsProgress(true);
        setUserQrDialogSetIsOpen(false);
        userQrDownload(param).then((r: { ok: boolean, result: any }) => {
            toast.success('QRコードの生成に成功しました');
            setQrFileUrl(r.result?.url);
            setUserQrResultDialogIsOpen(true);
        }).catch(e => {
            toast.error(`QRコードのダウンロードに失敗しました + ${e.toString()}`);
        }).finally(() => {
            setIsProgress(false);
        })
    }

    return (
        <GenericTemplate title="ユーザー">
            <Typography variant="h5" sx={{m: "1rem"}}>
                ユーザー一覧
            </Typography>

            {isLoading ? (<LinearProgress/>) : (<TableContainer component={Paper}>

                <div className="App-Content-Menu">
                    <Box>
                        <Button
                            disabled={tenant?.tenantId === undefined}
                            onClick={() => {
                                setIsOpenNewDialog(true);
                                openId.current = openId.current + 1;
                            }}><Create/>New</Button>
                        <Button disabled={checkedUsers.length === 0} onClick={() => {
                            setUserQrDialogIsOpen(true);
                        }}><QrCode2/>QR</Button>
                    </Box>
                </div>

                <UserForm
                    key={openId.current.toString()}
                    isOpen={isOpenNewDialog}
                    index={0}
                    onClose={setClose}
                    onUpdateItem={onCreateItem}
                    editMode={false}
                    model={new User({name: '', loginId: '', temporaryPassword: ''})}
                    jsonTemplate={jsonTemplate}
                    authType={tenant?.authType}
                    toast={toast}
                />

                <UserQrDialog
                    isOpen={userQrDialogIsOpen}
                    setIsOpen={setUserQrDialogSetIsOpen}
                    tenantId={tenantId}
                    userIds={[ ...checkedUsers ]}
                    onQrCreateButton={onCreateQrButton}>
                </UserQrDialog>

                <UserQrResultDialog
                    isOpen={userQrResultDialogIsOpen}
                    setIsOpen={setUserQrResultDialogIsOpen}
                    url={qrFileUrl}>
                </UserQrResultDialog>

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={checkedHeaderCheckbox}
                                    onChange={e => setCheckedHeaderCheckbox(e.target.checked)}/>
                            </TableCell>
                            <TableCell>利用者名</TableCell>
                            <TableCell align="left">ログインID</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageItems.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Checkbox checked={!!(item.id && checkedUsers.includes(item.id))}
                                              onChange={() => onChangeCheckBox(item.id)}/>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.name}
                                </TableCell>
                                <TableCell align="left">{item.loginId}</TableCell>
                                <TableCell align="left">{normalizedId(item.id)}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleOpen(index)}>
                                        <Edit/>
                                    </IconButton>
                                    <UserForm
                                        key={item.id}
                                        isOpen={isOpenEditDialog[index]}
                                        index={index}
                                        onClose={handleClose}
                                        onUpdateItem={onUpdateItem}
                                        editMode={true}
                                        model={item}
                                        jsonTemplate={jsonTemplate}
                                        toast={toast}
                                    />
                                    <IconButton onClick={() => {
                                        onRemoveItem({parentId: item.parentId, id: item.id})
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>)}
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isProgress}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </GenericTemplate>
    );

};
