import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Grid,
    Typography,
    Box
} from '@mui/material';
import {TUser, User} from "../../../model/user";
import {MuiForm5 as Form} from '@rjsf/material-ui';
import {JsonTemplate} from "../../../model/jsonTemplate";
import {toast, ToastContent, ToastOptions} from "react-toastify";
import {z} from "zod";

interface UserFormProps {
    key?: string,
    model: User,
    isOpen: boolean,
    index: number,
    editMode: boolean,
    jsonTemplate?: JsonTemplate,
    authType?: 'cognito' | 'cognito-external'
    toast: (content: ToastContent, options?: ToastOptions) => React.ReactText,
    onUpdateItem?: (item: TUser) => void,
    onClose: (index: number) => void,
}

export const UserForm: React.FC<UserFormProps> = (props) => {
    const [ nameProps, setNameProps ] = useState(props.model.name);
    const [ loginIdProps, setLoginIdProps ] = useState(props.model.loginId);
    const [ temporaryPassword, setTemporaryPassword ] = useState(props.model.temporaryPassword);
    const [ formData, setFormData ] = useState(props.model.jsonUser);
    const [ showDetail, setShowDetail ] = useState('none');
    const [ nameErrorText, setNameErrorText ] = useState("");
    const [ loginIdErrorText, setLoginIdErrorText ] = useState("");
    const [ passwordErrorText, setPasswordErrorText ] = useState("");

    const onChangeJson = (event: any) => {
        setFormData(event.formData)
    }

    const isSamlTenant = () => {
        return props.authType === 'cognito-external';
    }

    const validateForm = () => {
        const nameRule: z.ZodString = z.coerce.string().min(1, {message: '利用者名は必須です'})
        const userSchema = isSamlTenant() ? z.object(({
                name: nameRule,
                loginId: z.coerce.string().email({message: "有効なメールアドレスを指定してください"}),
            }))
            : z.object(({
                name: nameRule,
                loginId: z.coerce.string().min(3, {message: '3文字以上を指定してください'}),
                password: z.coerce.string().min(6, {message: '6文字以上を指定してください'})
            }));
        const result = userSchema.safeParse({
            name: nameProps,
            loginId: loginIdProps,
            password: temporaryPassword
        });
        console.log(result)
        if (!result.success) {
            setNameErrorText("")
            setLoginIdErrorText("");
            setPasswordErrorText("");
            result.error.issues.forEach(issue => {
                if ( issue.path.includes('name')) {
                    setNameErrorText(issue.message)
                }
                if ( issue.path.includes('password')) {
                    setPasswordErrorText(issue.message)
                }
                if ( issue.path.includes('loginId')) {
                    setLoginIdErrorText(issue.message)
                }
            });
        }

        return result.success;
    }

    return (
        <Dialog open={props.isOpen} onClose={() => props.onClose(props.index)} scroll="body">
            <DialogTitle>ユーザー{props.editMode ? '編集' : '登録'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="利用者名"
                    type="text"
                    fullWidth
                    error={nameErrorText.length > 0}
                    helperText={nameErrorText}
                    variant="standard"
                    onChange={e => setNameProps(e.target.value)}
                    value={nameProps}/>
                {(isSamlTenant()) ? (
                    <>
                        <TextField
                            margin="dense"
                            id="loginId"
                            label="emailアドレス"
                            type="text"
                            fullWidth
                            variant="standard"
                            error={loginIdErrorText.length > 0}
                            helperText={loginIdErrorText}
                            onChange={e => setLoginIdProps(e.target.value)}
                            disabled={props.editMode}
                            value={loginIdProps}/>
                    </>
                ) : (
                    <>
                        <TextField

                            margin="dense"
                            id="loginId"
                            label="ログインID"
                            type="text"
                            fullWidth
                            variant="standard"
                            error={loginIdErrorText.length > 0}
                            helperText={loginIdErrorText}
                            onChange={e => setLoginIdProps(e.target.value)}
                            disabled={props.editMode}
                            value={loginIdProps}/>
                        <TextField
                            margin="dense"
                            id="temporaryPassword"
                            label="パスワード"
                            type="text"
                            fullWidth
                            variant="standard"
                            helperText={passwordErrorText}
                            inputProps={{minLength: 5, maxLength: 64}}
                            onChange={e => setTemporaryPassword(e.target.value)}
                            value={temporaryPassword}/>
                    </>
                )}
                <Form
                    schema={props.jsonTemplate?.jsonSchema || {}}
                    uiSchema={props.jsonTemplate?.uiSchema || {}}
                    formData={formData}
                    onChange={onChangeJson}
                    onSubmit={(e) => {
                        if (!validateForm()) {
                            toast.error("入力内容にエラーがあります");
                            return;
                        }
                        const item: TUser = {
                            parentId: props.model.parentId,
                            id: props.model.id,
                            name: nameProps || '',
                            loginId: loginIdProps || '',
                            temporaryPassword: temporaryPassword,
                            jsonUser: formData
                        };
                        props.onUpdateItem && props.onUpdateItem(item);
                        props.onClose(props.index);
                    }}
                />
                <Box sx={{
                    m: 1,
                    display: showDetail
                }}>
                    <TextField
                        margin="dense"
                        value={JSON.stringify(formData)}
                        label="User data"
                        fullWidth multiline
                        InputProps={{
                            readOnly: true,
                        }}
                        onChange={e => {
                            console.log('TextField User data', e.target.value)
                            setFormData(JSON.parse(e.target.value))
                        }}
                    ></TextField>
                    <TextField
                        margin="dense"
                        value={JSON.stringify(props.jsonTemplate?.jsonSchema)}
                        label="JSON Schema"
                        fullWidth multiline
                        InputProps={{
                            readOnly: true,
                        }}
                    ></TextField>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDetail(showDetail === 'none' ? 'inline' : 'none')}>詳細</Button>
                <Button onClick={() => props.onClose(props.index)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}
