import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";
import {IBaseModel} from "./baseModel";

export type TenantAuthType = 'cognito' | 'cognito-external'

export type TUserAttributes = {
    name: string,
    loginId: string,
    temporaryPassword: string
    jsonUser?: any
};

export type TUser = TBaseModel & TUserAttributes;
export type TUserParam = TBaseModelParam & TUserAttributes;

export class User extends BaseModel {
    name: string | undefined;
    loginId: string | undefined;
    jsonUser: any | undefined;
    temporaryPassword: string;

    constructor(param: TUserParam) {
        super(param);
        this.name = param.name
        this.jsonUser = param.jsonUser
        this.loginId = param.loginId
        this.temporaryPassword = param.temporaryPassword
    }

    updateAttributes(obj: TUser) {
        if (obj.name !== undefined) {
            this.name = obj.name
        }
        if (obj.loginId !== undefined) {
            this.loginId = obj.loginId
        }
        if (obj.jsonUser !== undefined) {
            this.jsonUser = obj.jsonUser
        }
        if (obj.temporaryPassword !== undefined) {
            this.temporaryPassword = obj.temporaryPassword
        }
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            phoneticKey: this.phoneticKey,
            name: this.name,
            loginId: this.loginId,
            temporaryPassword: this.temporaryPassword,
            jsonUser: this.jsonUser,
            updatedAt: this.updatedAt
        }
    }

}


