import {z} from "zod";

// Example
// const setHelperTextFunctions: ZodHelperSetTextFunctions = {
//     'name': setNamePropsHelperText,
// }
export type ZodHelperSetTextFunctions = { [name: string]: (str: string) => void };

export const zodValidateForm = (schema: z.ZodObject<any>,
                                helperTextInitFunc: ZodHelperSetTextFunctions,
                                data: any) => {
    let errors = 0;
    Object.keys(helperTextInitFunc).forEach(name => {
        helperTextInitFunc[name]('');
    });
    const result = schema.safeParse(data);
    if (!result.success) {
        result.error.issues.forEach(issue => {
            issue.path.forEach(p => {
                const key = p as string;
                if (helperTextInitFunc[key]) {
                    helperTextInitFunc[key](issue.message)
                    errors++;
                }
            });
        });
    }
    return errors;
}