import BaseModel from "./baseModel";
import {IBaseModel} from "./baseModel";

export interface IDownloadFile {
    name: string,
    contentType: string,
}

export class DownloadFile {
    name: string;
    contentType: string;

    constructor(param: IDownloadFile) {
        this.name = param.name
        this.contentType = param.contentType
    }

    updateAttributes(obj: IDownloadFile) {
        if (obj.name !== undefined) {
            this.name = obj.name
        }
        if (obj.contentType !== undefined) {
            this.contentType = obj.contentType
        }
    }

    json() {
        return {
            name: this.name,
            contentType: this.contentType,
        }
    }

}


