import React, {useEffect, useState} from "react";
import GenericTemplate from "../../templates/GenericTemplate";
import {MuiForm5 as Form} from '@rjsf/material-ui';
import {useParams} from "react-router-dom";
import {getJsonTemplateById} from "../../../modules/acmv3-api/jsonTemplate";
import {getTenantById, putTenant} from "../../../modules/acmv3-api/tenants"
import {TTenant, Tenant} from "../../../model/tenant";
import {Box, Grid, LinearProgress, TextField} from "@mui/material";
import {toast} from 'react-toastify';
import {JsonTemplate} from "../../../model/jsonTemplate";

export const TenantPage: React.FC = () => {
    const [ tenantUuid, ] = useState(useParams().parentId); // tenantUuid
    const [ parentProviderId, setParentProviderId ] = useState<string|undefined>();
    const [ tenant, setTenant ] = useState<Tenant>()
    const [ isLoading, setIsLoading ] = useState(true);
    const [ jsonSchema, setJsonSchema ] = useState({});
    const [ uiSchema, setUiSchema ] = useState({});
    const [ formData, setFormData ] = useState({});

    useEffect(() => {
        if (tenantUuid) {
            getTenantById(tenantUuid).then(t => {
                setParentProviderId(t.parentId)
                setTenant(t);
                setFormData(t.jsonTenant)
                return t.tenantUiSchemaId ?
                    getJsonTemplateById(t.tenantUiSchemaId) : Promise.resolve(undefined);
            }).then((jsonTemplate?: JsonTemplate) => {
                setJsonSchema(jsonTemplate?.jsonSchema||{})
                setUiSchema(jsonTemplate?.uiSchema||{})
                setIsLoading(false);
            }).catch((e: any) => {
                toast.error(`読み込みに失敗しました ${e.toString()}`);
                setIsLoading(false);
            });
        }
    }, [ tenantUuid ]);

    const onChangeJson = (event: any) => {
        console.log({...{eventType: 'onChangeJson'}, ...{eventData: event}});
        setFormData(event.formData)
    }

    const onSubmitJson = (event: any) => {
        console.log({...{eventType: 'onSubmitJson'}, ...{eventData: event}});
        const tenant: TTenant = {
            parentId: parentProviderId,
            id: tenantUuid,
            jsonTenant: event.formData
        };
        putTenant(tenant).then(({body, fetchResult}) => {
            if ( fetchResult.ok ) {
                toast.success('更新しました');
            } else {
                toast.error('更新に失敗しました ' + fetchResult.statusText);
            }
            // console.log('TenantPage.onSubmitJson putTenant done', body, fetchResult)
        }).catch(e => {
            // console.error('TenantPage.onSubmitJson Error', e)
            toast.error(`更新に失敗しました (Err:${e.toString()}`)
        })
    }

    return (
        <GenericTemplate title="テンプレート">
            <div>
                { isLoading ? ( <LinearProgress/>) : (
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <TextField
                                id="filled-read-only-input"
                                label="テナント名"
                                defaultValue={tenant?.name}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="filled-read-only-input"
                                label="テナントID"
                                defaultValue={tenant?.tenantId}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Form
                                    schema={jsonSchema}
                                    uiSchema={uiSchema}
                                    formData={formData}
                                    onChange={onChangeJson}
                                    onSubmit={onSubmitJson}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </div>
        </GenericTemplate>
    );
};

