import {API_BASE_URL} from "../acmv3-api";
import {TModelKey} from "../../model/baseModel";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";
import {Admin, TAdmin, TAdminParam} from "../../model/admin";

export const getProviderAdminsUri = (param: TGetItemsUrlParam) => {
    return `${API_BASE_URL}/providers/${param.parentId}/admins` + getItemsUrlParameter(param);
}

const getProviderAdminUri = (key: TModelKey) => {
    return `${API_BASE_URL}/providers/${key.parentId}/admins/${key.id}`;
}

const getAdminUri = (adminId: string) => {
    return `${API_BASE_URL}/admins/${adminId}`;
}

export const getProviderAdmins = async (param: TGetItemsUrlParam): Promise<any[]> => {
    console.log(`getProviderAdmins parentId: ${param.parentId}`)
    const url = getProviderAdminsUri({...param, ...{method: 'GET'}});
    const res = await fetch(url, await getFetchOption());
    const json = await res.json();
    console.log('getUsers', json)
    return json;
};

export const getAdminById = async (tenantId: string) => {
    const res = await fetch(getAdminUri(tenantId), await getFetchOption());
    return new Admin(await res.json());
};

export const postAdmin = async (args: { parentId: string, item: TAdmin }) => {
    console.log(`postProviderAdmin: item: ${JSON.stringify(args.item)}`);

    // adminTenantIdsが設定されている場合はテナント管理者
    // そうでない場合はプロパイダ管理者として登録する
    const adminTenantIds = (args.item.adminTenantIds.length > 0 ) ? args.item.adminTenantIds : [];
    const adminProviderId = (args.item.adminTenantIds.length === 0 ) ? args.item.adminProviderId : undefined;
    const param: TAdminParam = {
        parentId: args.parentId,
        name: args.item.name,
        email: args.item.email,
        temporaryPassword: args.item.temporaryPassword,
        adminTenantIds,
        adminProviderId
    };

    const model = new Admin(param);
    const method = 'POST';
    console.log(`postUser: model.json: ${JSON.stringify(model.json())}`);
    const postParam: TGetItemsUrlParam = {
        parentId: args.parentId,
        method,
    }
    const fetchResult = await fetch(getProviderAdminsUri(postParam),
        await getFetchOption({method, body: model.json()}));
    console.log('postAdmin fetchResult', await fetchResult)

    return {body: await fetchResult.json(), fetchResult};
}

export const putAdmin = async (item: TAdmin) => {
    console.log(`putAdmin: item: ${JSON.stringify(item)}`);
    const model = new Admin(item);
    const method = 'PUT';
    const option: TGetFetchOptionParam = {method, body: model.json()};
    console.log(`putAdmin: url: ${getProviderAdminUri(model.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getProviderAdminUri(model.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
}

export const deleteProviderAdmin = async (key: TModelKey) => {
    const method = 'DELETE';
    const option: TGetFetchOptionParam = {method};
    console.log(`deleteTenant: url: ${getProviderAdminUri(key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getProviderAdminUri(key), await getFetchOption(option));
    console.log('deleteUser', fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
}
