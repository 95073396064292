// {ok: false, statusCode: 400, status: 'Wrong parameter', error: 'UsernameExistsException'}
interface FetchResponseBody {
    ok: boolean,
    statusCode: number,
    status: string,
    error: string
}

export const generateFetchErrorMessage = (fetchResponseBody: FetchResponseBody) => {

    let message = '';
    switch (fetchResponseBody.error) {
        case 'UsernameExistsException':
            message = 'ユーザーはすでに存在しています';
            break;
        default:
            message = (fetchResponseBody.error || fetchResponseBody.status || fetchResponseBody.statusCode) as string;
    }
    return message;
};
