import {API_BASE_URL} from "../acmv3-api";
import {TModelKey} from "../../model/baseModel";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";
import {RegAgentServerSlot, TRegAgentServerSlot, TRegAgentServerSlotParam} from "../../model/regAgentServerSlot";
import {Auth} from "aws-amplify";

type TGetRegAgentServerSlotsUri = {
    parentId?: string,
    exclusiveStartKey?: string,
    namePrefix?: string
}
export const getRegAgentServerSlotsUri = async (key: TGetRegAgentServerSlotsUri = {}) => {
    let {parentId, exclusiveStartKey, namePrefix} = key;
    if (!parentId) {
        const user = await Auth.currentAuthenticatedUser();
        parentId = user.attributes['custom:providerId'];
    }
    if ( !parentId ) {
        //Check Admin user;
        throw new Error('Error providerId is undefined')
    }
    let uri = `${API_BASE_URL}/reg-agents/${parentId}/slots?perPage=15`;
    if (exclusiveStartKey) {
        uri += `&exclusiveStartKey=${exclusiveStartKey}`;
    }
    if (namePrefix) {
        uri += `&name=${namePrefix}`;
    }
    return uri;
}

const getRegAgentServerSlotUri = (regAgentServerId: string, slotId: string) => {
    return `${API_BASE_URL}/reg-agents/${regAgentServerId}/slots/${slotId}`;
}

export const postRegAgentServerSlot = async (args: { parentId: string, item: TRegAgentServerSlot }) => {

    const param: TRegAgentServerSlotParam = {
        parentId: args.parentId,
        name: args.item.name,
        tenantId: args.item.tenantId,
        slotNo: args.item.slotNo,
        maxUser: args.item.maxUser,
        pushRate: args.item.pushRate
    };

    const model = new RegAgentServerSlot(param);
    const method = 'POST';
    const postParam: TGetItemsUrlParam = {
        parentId: args.parentId,
        method,
    }
    const fetchResult = await fetch(await getRegAgentServerSlotsUri(postParam),
        await getFetchOption({method, body: model.json()}));
    console.log('postRegAgentServerSlot fetchResult', await fetchResult)

    return {body: await fetchResult.json(), fetchResult};
}

export const putRegAgentServerSlot = async (item: TRegAgentServerSlot) => {
    console.log(`putAdmin: item: ${JSON.stringify(item)}`);
    const model = new RegAgentServerSlot(item);
    const method = 'PUT';
    const option: TGetFetchOptionParam = {method, body: model.json()};
    if ( !(model.parentId && model.id) ) {
        throw new Error('putRegAgentServerSlot Invalid tenant Id or slot no')
    }
    console.log(`putAdmin: url: ${getRegAgentServerSlotUri(model.parentId, model.id)} option: ${JSON.stringify(option)}`);
    const fetchResult = await fetch(getRegAgentServerSlotUri(model.parentId, model.id), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
}

export const deleteRegAgentServerSlot = async (regAgentServerId: string, slotId: string) => {
    const method = 'DELETE';
    const option: TGetFetchOptionParam = {method};
    console.log(`deleteRegAgentServerSlot: param: ${{regAgentServerId, slotId}} url: ${getRegAgentServerSlotUri(regAgentServerId, slotId)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getRegAgentServerSlotUri(regAgentServerId, slotId), await getFetchOption(option));
    console.log('deleteRegAgentServerSlot', fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
}
