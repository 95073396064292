import React, {useEffect, useState} from "react";
import {z} from "zod";
import {Button, FormLabel, Grid} from "@mui/material";
import {FormTextField} from "../../common/FormTextField/index"
import {Dialog} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';

import {Tenant, TTenant, TTenantParam} from "../../../model/tenant";
import {JsonTemplate} from "../../../model/jsonTemplate";
import {SelectJsonTemplate} from "../../elements/SelectJsonTemplate";
import {getJsonTemplates} from "../../../modules/acmv3-api/jsonTemplate";

export interface ITenantFormProps {
    key?: string,
    model: Tenant,
    isOpen: boolean,
    isFullScreen?: boolean,
    index?: number
    editMode: boolean,
    onUpdateItem?: (newProvider: TTenant) => void,
    onCreateItem?: (newProvider: TTenantParam) => void,
    onClose: (index: number | undefined) => void
}

export const ProviderTenantForm = (props: ITenantFormProps) => {
    const [ nameProps, setNameProps ] = useState<string>(props.model?.name || '');
    const [ maxUserProps, setMaxUserProps ] = useState(props.model?.maxUser?.toString() || '10');
    const [ tenantIdProps, setTenantIdProps ] = useState(props.model?.tenantId || '');
    const [ tenantUiSchemaId, setTenantUiSchemaId ] = React.useState(props.model?.tenantUiSchemaId);
    const [ userUiSchemaId, setUserUiSchemaId ] = React.useState(props.model?.userUiSchemaId);
    const [ templates, setTemplates ] = React.useState<JsonTemplate[]>([]);
    const [ namePropsHelperText, setNamePropsHelperText ] = useState('');
    const [ maxUserPropsHelperText, setMaxUserPropsHelperText ] = useState('');
    const [ tenantIdPropsHelperText, setTenantIdPropsHelperText ] = useState('');

    const [ pbsv3EnableIntegration, setEnablePbsv3Integration ] = useState(!!(props.model?.pbsv3ApiHost) || false);
    const [ pbsv3ApiHost, setPbsv3ApiHost ] = useState(props.model?.pbsv3ApiHost as string || '');
    const [ pbsv3AdminEmail, setPbsv3AdminEmail ] = useState(props.model?.pbsv3AdminEmail as string || '');
    const [ pbsv3AdminPassword, setPbsv3AdminPassword ] = useState(props.model?.pbsv3AdminPassword as string || '');
    const [ pbsv3MaxUserCount, setPbsv3MaxUserCount ] = useState(props.model?.pbsv3MaxUserCount as string || '50');

    const [ pbsv3ApiHostHelperText, setPbsv3ApiHostHelperText ] = useState('');
    const [ pbsv3AdminEmailHelperText, setPbsv3AdminEmailHelperText ] = useState('');
    const [ pbsv3AdminPasswordHelperText, setPbsv3AdminPasswordHelperText ] = useState('');
    const [ pbsv3MaxUserCountHelperText, setPbsv3MaxUserCountHelperText ] = useState('');
    const [ validateErrors, setValidateErrors ] = useState(99);
    const [ useSamlAuth, setUseSamlAuth ] = useState(props.model?.authType === 'cognito-external' || false);
    const [ helperTextIsError, setHelperTextIsError ] = useState(false)

    useEffect(() => {
        getJsonTemplates().then(templates => {
            const models = templates.Items.map((template: any) => {
                    return new JsonTemplate({
                        parentId: template.parentId,
                        id: template.id,
                        name: template.name,
                        jsonSchema: template.jsonSchema,
                        uiSchema: template.uiSchema
                    })
                }
            )
            setTemplates(models)
        })
    }, []);

    const onTenantTemplateChange = (templateId: string | undefined, value: string) => {
        setTenantUiSchemaId(value)
    }

    const onUserTemplateChange = (templateId: string | undefined, value: string) => {
        setUserUiSchemaId(value)
    }

    // Allow symbols: ^$*.[]{}()?"!@#%&/\,><':;|_~`=+-
    const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[-\da-zA-Z^$*[\](){}?@#%&"!/,<>:;'|_~`=+]{8,32}$/;
    const schema = z.object({
        nameProps: z.string()
            .min(1, "必須項目")
            .max(32, "32文字以下で指定してください"),
        maxUserProps: z.preprocess(a => Number.parseInt(z.string().parse(a), 10),
            z.number({required_error: "必須項目", invalid_type_error: '数値を入力して下さい'})
                .max(9999, '9999以下に設定してください')),
        tenantIdProps: z.string()
            .min(5, "5文字以上で設定してください")
            .max(16, "16文字以下で設定してください")
            .regex(/^[A-Za-z0-9][A-Za-z0-9._-]+$/, "1文字目は半角英数記号のみ指定できます")
            .regex(/^[A-Za-z0-9._-]+$/, "半角英数記号(._-)のみ指定可能です")
        ,
        pbsv3ApiHost: z.string().url("有効なURLを設定してください"),
        pbsv3AdminEmail: z.string().email("メールアドレスを指定してください"),
        pbsv3MaxUserCount: z.preprocess(
            a => Number.parseInt(z.string().parse(a), 10),
            z.number().min(1).max(5000)),
        pbsv3AdminPassword: z.string().regex(passwordRegex,
            "パスワードは小文字・大文字・数字を含む8文字以上32文字以内で設定してください")
    });

    const setHelperTextFunc: { [name: string]: (str: string) => void } = {
        'nameProps': setNamePropsHelperText,
        'maxUserProps': setMaxUserPropsHelperText,
        'tenantIdProps': setTenantIdPropsHelperText,
        'pbsv3ApiHost': setPbsv3ApiHostHelperText,
        'pbsv3AdminEmail': setPbsv3AdminEmailHelperText,
        'pbsv3MaxUserCount': setPbsv3MaxUserCountHelperText,
        'pbsv3AdminPassword': setPbsv3AdminPasswordHelperText,
    }

    const validatePbsv3Form = () => {
        let errors = 0;
        Object.keys(setHelperTextFunc).forEach(name => {
            setHelperTextFunc[name]('');
        });

        const result = schema.safeParse({
            nameProps, maxUserProps, tenantIdProps,
            pbsv3ApiHost, pbsv3AdminEmail, pbsv3MaxUserCount, pbsv3AdminPassword
        });
        if (!result.success) {
            result.error.issues.forEach(issue => {
                issue.path.forEach(p => {
                    const key = p as string;
                    if (!pbsv3EnableIntegration && key.startsWith('pbsv3')) {
                        return;
                    }
                    if (setHelperTextFunc[key]) {
                        setHelperTextFunc[key](issue.message)
                        errors++;
                    }
                });
            });
        }
        setValidateErrors(errors);
    }

    React.useEffect(() => {
        validatePbsv3Form();
    }, [
        nameProps, maxUserProps, tenantIdProps,
        pbsv3ApiHost, pbsv3AdminEmail, pbsv3AdminPassword, pbsv3MaxUserCount, pbsv3EnableIntegration
    ]);

    return (
        <Dialog fullWidth={true} maxWidth={'md'} key={props.key} open={props.isOpen} fullScreen={false}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") {
                        return;
                    }
                    props.onClose(props.index)
                }}>
            <DialogTitle sx={{fontWeight: 'bold'}}>テナント{props.editMode ? '設定' : '登録'}</DialogTitle>
            <Divider/>
            <DialogContent>
                <FormGroup sx={{b: 1, p: 1}}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sx={{p: 1}}>
                            <FormTextField id={"name"} label={"テナント名"}
                                           value={nameProps} setProps={setNameProps} autofocus={true}
                                           helperText={namePropsHelperText} helperTextIsError={helperTextIsError}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 1}}>
                            <FormTextField id={"tenantId"} label={"テナントID"}
                                           disabled={props.editMode}
                                           value={tenantIdProps} setProps={setTenantIdProps}
                                           helperText={tenantIdPropsHelperText} helperTextIsError={helperTextIsError}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 1}}>
                            <FormTextField id={"maxUser"} label={"最大ユーザー数"}
                                           value={maxUserProps} setProps={setMaxUserProps}
                                           helperText={maxUserPropsHelperText} helperTextIsError={helperTextIsError}/>
                        </Grid>
                    </Grid>

                    <FormLabel sx={{mt: 2, fontSize: 20, fontWeight: 'bold'}}>テンプレート</FormLabel>
                    <Divider sx={{mb: 2}}/>
                    <Grid container spacing={2}>
                        <Grid xs={6} sx={{p: 2}}>
                            <SelectJsonTemplate elementId="tenant-template-json"
                                                label={"テナント"}
                                                modelId={props.model.tenantUiSchemaId}
                                                templates={templates}
                                                templateId={tenantUiSchemaId}
                                                onChangeSelect={onTenantTemplateChange}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 2}}>
                            <SelectJsonTemplate elementId="user-template-json"
                                                label={"ユーザー"}
                                                modelId={props.model.userUiSchemaId}
                                                templates={templates}
                                                templateId={userUiSchemaId}
                                                onChangeSelect={onUserTemplateChange}/>
                        </Grid>
                    </Grid>
                </FormGroup>

                <FormGroup sx={{ p: 1}}>
                    <FormLabel sx={{fontSize: 20, fontWeight: 'bold'}}>+電話帳v3</FormLabel>
                    <Divider/>
                    <Grid container spacing={2}>
                        <Grid xs={12} sx={{pt: 3}}>
                            <FormControlLabel label="+電話帳v3 テナントを作成する" disabled={props.editMode} control={
                                <Checkbox
                                    disabled={props.editMode}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setEnablePbsv3Integration(e.target.checked)
                                    }}
                                    checked={pbsv3EnableIntegration}

                                />
                            }/>
                        </Grid>
                        <Grid xs={12} sx={{p: 1}}>
                            <FormTextField id={"pbs3ApiHost"} label={"+電話帳v3 API Host"} autofocus={true}
                                           value={pbsv3ApiHost} setProps={setPbsv3ApiHost}
                                           disabled={props.editMode || !pbsv3EnableIntegration}
                                           helperText={pbsv3ApiHostHelperText} helperTextIsError={helperTextIsError}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 1}} >
                            <FormTextField id={"pbs3AdminEmail"} label={"管理者メールアドレス"} value={pbsv3AdminEmail}
                                           setProps={setPbsv3AdminEmail} autofocus={true}
                                           disabled={props.editMode || !pbsv3EnableIntegration}
                                           helperText={pbsv3AdminEmailHelperText}
                                           helperTextIsError={helperTextIsError}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 1}}>
                            <FormTextField id={"pbs3AdminPassword"} label={"管理者パスワード"} value={pbsv3AdminPassword}
                                           setProps={setPbsv3AdminPassword} autofocus={true}
                                           disabled={props.editMode || !pbsv3EnableIntegration}
                                           helperText={pbsv3AdminPasswordHelperText}
                                           helperTextIsError={helperTextIsError}/>
                        </Grid>
                        <Grid xs={6} sx={{p: 1}}>
                            <FormTextField id={"pbs3MaxUserCount"} label={"最大ユーザー数"} value={pbsv3MaxUserCount}
                                           setProps={setPbsv3MaxUserCount} autofocus={true}
                                           disabled={props.editMode || !pbsv3EnableIntegration}
                                           helperText={pbsv3MaxUserCountHelperText}
                                           helperTextIsError={helperTextIsError}/>
                        </Grid>
                    </Grid>
                </FormGroup>
                <FormGroup>
                    <FormLabel sx={{m: 1, fontSize: 20, fontWeight: 'bold'}} component="label">SAML認証</FormLabel>
                    <Divider/>
                    <FormControlLabel label="SAML認証を利用する（新規登録時のみ変更可能・ご利用には申込が必要）" control={
                        <Checkbox
                            disabled={props.editMode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setUseSamlAuth(e.target.checked)
                            }}
                            checked={useSamlAuth}
                        />
                    }/>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button className="App-Form-Button" disabled={validateErrors > 0} onClick={() => {
                    const tenant: TTenantParam = {
                        parentId: props.model?.parentId || '',
                        id: props.model?.id || '',
                        name: nameProps,
                        tenantId: tenantIdProps,
                        maxUser: parseInt(maxUserProps),
                        tenantUiSchemaId: tenantUiSchemaId,
                        userUiSchemaId: userUiSchemaId,
                    };
                    if (pbsv3EnableIntegration && !props.editMode) {
                        tenant.pbsv3AdminEmail = pbsv3AdminEmail;
                        tenant.pbsv3AdminPassword = pbsv3AdminPassword;
                        tenant.pbsv3MaxUserCount = pbsv3MaxUserCount;
                        tenant.pbsv3ApiHost = pbsv3ApiHost;
                        tenant.authType = useSamlAuth ? 'cognito-external' : 'cognito'
                    }
                    if (props.editMode) {
                        props.onUpdateItem && props.onUpdateItem(tenant as TTenant);
                    } else {
                        props.onCreateItem && props.onCreateItem(tenant as TTenantParam);
                    }
                    props.onClose(props.index);
                }}>
                    <SaveIcon/> {props.editMode ? "更新" : "新規登録"}
                </Button>
                <Button className="App-Form-Button"
                        onClick={() => {
                            props.onClose(props.index);
                        }}><CloseIcon/>Close
                </Button>
            </DialogActions>
        </Dialog>
)
};
