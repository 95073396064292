import * as React from "react";
import {Box, TextField} from "@mui/material";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

interface DatePickerDayProps {
    label: string;
    value: string;
    setProps: React.Dispatch<React.SetStateAction<string>>
    disabled?: boolean
}

const DatePickerDay: React.FC<DatePickerDayProps> = (props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
            <Box sx={{m: 2}}>
                <DatePicker
                    disabled={props.disabled}
                    label={props.label}
                    value={props.value}
                    inputFormat='yyyy/MM/dd'
                    onChange={e => props.setProps(e || '')}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DatePickerDay;