import React from "react";
import GenericTemplate from "../../templates/GenericTemplate";

export const HomePage: React.FC = () => {
  return (
    <GenericTemplate title="トップページ">
      <></>
    </GenericTemplate>
  );
};
