import React, {useState} from "react";
import PropTypes from "prop-types";
import {z} from "zod";

import {Dialog, Button} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {FormTextField} from '../../common/FormTextField';
import Divider from "@mui/material/Divider";
import {RegAgentServerSlot, TRegAgentServerSlot} from "../../../model/regAgentServerSlot";
import {ZodHelperSetTextFunctions, zodValidateForm} from "../helper/zodHelper";

export interface IRegAgentServerSlotFormProps {
    key?: string,
    model?: RegAgentServerSlot,
    isOpen: boolean,
    index?: number
    editMode: boolean,
    onUpdateItem: (newProvider: TRegAgentServerSlot) => void,
    onClose: (index: number | undefined) => void
}

const schema = z.object(({
    name: z.coerce.string()
        .min(1, {message: '利用者名は必須です'})
        .max(64, {message: '64文字以内で指定してください'}),
    slotNo: z.preprocess(
        a => Number.parseInt(z.string().parse(a), 10),
        z.number().min(0,{message: '0以上で指定してください'})
            .max(99, {message: '99以下で指定してください'})),
    maxUser: z.preprocess(
        a => Number.parseInt(z.string().parse(a), 10),
        z.number().min(0,{message: '0以上で指定してください'})
            .max(999, {message: '999以下で指定してください'})),
    pushRate: z.preprocess(
        a => Number.parseInt(z.string().parse(a), 10),
        z.number().min(0).max(999))
}));

export const RegAgentServerSlotForm = (props: IRegAgentServerSlotFormProps) => {
    const [ nameProps, setNameProps ] = useState<string>(props.model?.name || '');
    const [ slotNoProps, setSlotNoProps ] = useState(props.model?.slotNo?.toString() || '');
    const [ tenantIdProps, setTenantIdProps ] = useState(props.model?.slotNo?.toString() || '');
    const [ maxUserProps, setMaxUserProps ] = useState(props.model?.maxUser?.toString() || '8');
    const [ pushRateProps, setPushRateProps ] = useState(props.model?.pushRate?.toString() || '30');
    const [ errors, setErrors ] = useState(99);
    const [ namePropsHelperText, setNamePropsHelperText ] = useState('');
    const [ slotNoPropsHelperText, setSlotNoPropsHelperText ] = useState('');
    const [ tenantIdPropsHelperText, setTenantIdPropsHelperText ] = useState('');
    const [ maxUserPropsHelperText, setMaxUserPropsHelperText ] = useState('');
    const [ pushRatePropsHelperText, setPushRatePropsHelperText ] = useState('');

    const setHelperTextFunctions: ZodHelperSetTextFunctions = {
        'name': setNamePropsHelperText,
        'slotNo': setSlotNoPropsHelperText,
        'tenantId': setTenantIdPropsHelperText,
        'maxUser': setMaxUserPropsHelperText,
        'pushRate': setPushRatePropsHelperText,
    };

    return (
        <Dialog key={props.key} open={!!props.isOpen} onClose={() => props.onClose(props.index)}>
            <DialogTitle sx={{fontWeight: 'bold'}}>＋PUSH RegAgentサーバー{props.editMode ? '編集' : '登録'}</DialogTitle>
            <Divider/>
            <DialogContent>
                <FormTextField id={"name"} label={"スロット名"}
                               value={nameProps} setProps={setNameProps} autofocus={true}
                               helperText={namePropsHelperText}/>
                <FormTextField id={"slotNo"} label={"スロット番号"}
                               value={slotNoProps} setProps={setSlotNoProps}
                               helperText={slotNoPropsHelperText}/>
                <FormTextField id={"tenantId"} label={"テナントID"}
                               value={tenantIdProps} setProps={setTenantIdProps}
                               helperText={tenantIdPropsHelperText}/>
                <FormTextField id={"maxUser"} label={"最大ユーザー数"}
                               value={maxUserProps} setProps={setMaxUserProps}
                               helperText={maxUserPropsHelperText}/>
                <FormTextField id={"pushRate"} label={"PUSHレート"}
                               value={pushRateProps} setProps={setPushRateProps}
                               helperText={pushRatePropsHelperText}/>
            </DialogContent>
            <DialogActions>
                <Button className="App-Form-Button"
                        onClick={() => {
                            setErrors(zodValidateForm(schema, setHelperTextFunctions, {
                                name: nameProps,
                                slotNo: slotNoProps,
                                tenantId: tenantIdProps,
                                maxUser: maxUserProps,
                                pushRate: pushRateProps,
                            }));
                            if ( errors > 0 ) {
                                return;
                            }
                            const model: TRegAgentServerSlot = {
                                parentId: props.model?.parentId || '',
                                id: props.model?.id || '',
                                name: nameProps,
                                slotNo: Number.parseInt(tenantIdProps),
                                tenantId: tenantIdProps,
                                maxUser: Number.parseInt(maxUserProps),
                                pushRate: Number.parseInt(pushRateProps),
                            };
                            props.onUpdateItem(model);
                            props.onClose(props.index);
                        }}>
                    <SaveIcon/> {props.editMode ? "更新" : "新規登録"}
                </Button>
                <Button className="App-Form-Button" onClick={() => {
                    props.onClose(props.index);
                }}><CloseIcon/>Close</Button>
            </DialogActions>
        </Dialog>
    )
};

RegAgentServerSlotForm.propTypes = {
    tenant: PropTypes.object,
    editMode: PropTypes.bool,
    onUpdateItem: PropTypes.func,
    onModalClose: PropTypes.func,
};