import React, {useState} from "react";
import {BrowserRouter, Route, Routes, Link} from "react-router-dom";
import {Authenticator} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {TenantPage} from "./components/pages/tenant/TenantPage";
import {HomePage} from "./components/pages/top/HomePage";
import {UsersPage} from "./components/pages/users/UsersPage";
import {ProvidersPage} from "./components/pages/providers/ProvidersPage"
import {ProviderTenantsPage} from "./components/pages/providerTenants/ProviderTenantsPage"
import {JsonEditorPage} from "./components/pages/jsonTemplate/JsonEditorPage";
import {JsonTemplatesPage} from "./components/pages/jsonTemplate/JsonTemplatesPage";
import {DownloadFilesPage} from "./components/pages/files/DownloadFilesPage";
import {ProviderAdminsPage} from "./components/pages/providerAdmins/ProviderAdminsPage";
import Amplify, {Auth} from "aws-amplify";
import amplifyConfig from "./amplify-config";

import './App.css';
import {NotFound} from "./components/pages/notFound/NotFound";
import {RegAgentServerPage} from "./components/pages/regagentServer/RegagentServerPages";
import {RegAgentServerSlotPage} from "./components/pages/regagentServerSlot/RegAgentServerSlotPages";

Amplify.configure({
    ...amplifyConfig, ...{
        aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        aws_cognito_region: process.env.REACT_APP_AWS_REGION || 'ap-northeast-1',
        aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
    }
});

const App: React.FC = () => {
    const [ userProviderId, setUserProviderId ] = React.useState<string>();
    return (

        <Authenticator hideSignUp={true}>
            {({signOut, user}) => (
                <>
                    {(user?.attributes) && setUserProviderId(user.attributes["custom:providerId"])}
                    <BrowserRouter>
                        <ToastContainer/>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            {
                                userProviderId && (
                                    <>
                                        <Route path="/providers/" element={<ProvidersPage/>}/>
                                        <Route path="/providers/:parentId" element={<ProvidersPage/>}/>
                                        <Route path="/providers/:parentId/tenants" element={<ProviderTenantsPage/>}/>
                                        <Route path="/providers/:parentId/admins" element={<ProviderAdminsPage/>}/>
                                        <Route path="/providers/:parentId/json-templates" element={<JsonTemplatesPage/>}/>
                                        <Route path="/providers/:parentId/json-templates/:id" element={<JsonEditorPage/>}/>
                                        <Route path="/providers/:parentId/reg-agents" element={<RegAgentServerPage/>}/>
                                        <Route path="/reg-agents/:parentId/slots" element={<RegAgentServerSlotPage/>}/>
                                    </>
                                )
                            }
                            <Route path="/tenants/:parentId/template" element={<TenantPage/>}/>
                            <Route path="/tenants/:parentId/users" element={<UsersPage/>}/>
                            <Route path="/tenants/:parentId/download-files" element={<DownloadFilesPage/>}/>
                            <Route path="*" element={<NotFound/>} />
                        </Routes>
                    </BrowserRouter>
                </>
            )}
        </Authenticator>
    );
};



export default App;
