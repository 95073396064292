import React, {useEffect, useRef, useState} from "react";

import {JsonTemplate} from "../../../model/jsonTemplate";
import {Dialog, Button, Grid, Typography} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import {FormTextField} from '../../common/FormTextField';
import {JsonEditor} from "../../elements/JsonEditor";
import { setJsonEditorRef } from "../../../hooks/refJsonEditor";
import {MuiForm5 as Form} from '@rjsf/material-ui';

export interface IJsonTemplateFormProps {
    model?: JsonTemplate,
    isOpen: boolean,
    index?: number
    editMode: boolean,
    onUpdateItem: (updateItem: JsonTemplate) => void,
    onClose: (index: number | undefined) => void,
    onChangeJson: (jsonType: 'jsonSchema'|'uiSchema', id: string | undefined, jsonSchema: React.ChangeEvent<HTMLInputElement>) => void
}

const isPresent = (json: string) => {
    return Object.keys(JSON.parse(json)).length > 0
}

export const JsonTemplateForm = (props: IJsonTemplateFormProps) => {
    const [ nameProps, setNameProps ] = useState<string>(props.model?.name || '');
    const jsonSchemaEditorRef = useRef(null);
    const setJsonSchemaEditorRef = setJsonEditorRef(jsonSchemaEditorRef);
    const [focusEditor, setFocusEditor] = useState<string>();
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const uiSchemaEditorRef = useRef(null);
    const setUiSchemaEditorRef = setJsonEditorRef(uiSchemaEditorRef)

    useEffect(() => {
            //すでにFocusの場合は実施しない。
            if (jsonSchemaEditorRef.current !== null && focusEditor !== 'jsonSchema') {
                // @ts-ignore
                jsonSchemaEditorRef.current.set(props.model?.jsonSchema);
            }
        },[focusEditor, props.model?.jsonSchema]);

    useEffect(() => {
        if (uiSchemaEditorRef.current !== null && focusEditor !== 'uiSchema') {
            // @ts-ignore
            uiSchemaEditorRef.current.set(props.model?.uiSchema);
        }
    }, [focusEditor, props.model?.uiSchema]);

    const onFocusEditor = (name: string) => {
        setFocusEditor(name);
    }

    return (
        <Dialog maxWidth="xl" open={props.isOpen} onClose={() => props.onClose(props.index)}>
            <Dialog open={isPreviewOpen} onClose={() => {setIsPreviewOpen(false)}}>
                <DialogTitle>
                    <span>プレビュー</span>
                </DialogTitle>
                <DialogContent >
                    <Form schema={props.model?.jsonSchema} uiSchema={props.model?.uiSchema} />
                </DialogContent>
            </Dialog>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xl={12}>
                        <DialogTitle>JSONテンプレート{props.editMode ? '編集' : '登録'}</DialogTitle>
                    </Grid>
                    <Grid item xl={12}>
                        <FormTextField id={"name"} label={"JSONテンプレート名"}
                                       value={nameProps} setProps={setNameProps} autofocus={true}/>
                    </Grid>
                    <Grid item xl={6}>
                        <Typography>JSON Schema</Typography>
                        <JsonEditor
                            name={'Schema'}
                            value={props.model?.jsonSchema}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeJson('jsonSchema', props.model?.id, e)}
                            onFocus={() => onFocusEditor('jsonSchema')}
                            onError={e => console.log('onError', e)}
                            setEditorRef={setJsonSchemaEditorRef}
                        />
                    </Grid>
                    <Grid item xl={6}>
                        <Typography>UI Schema</Typography>
                        <JsonEditor
                            name={'UI Schema'}
                            value={props.model?.uiSchema}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeJson('uiSchema', props.model?.id, e)}
                            onFocus={(e:FocusEvent) => onFocusEditor('uiSchema')}
                            setEditorRef={setUiSchemaEditorRef}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className="App-Form-Button" onClick={() => {setIsPreviewOpen(true);}}><PreviewIcon/>Preview</Button>
                <Button className="App-Form-Button" onClick={() => {
                    const item: JsonTemplate = new JsonTemplate({
                        parentId: props.model?.parentId,
                        id: props.model?.id,
                        name: nameProps,
                        jsonSchema: props.model?.jsonSchema,
                        uiSchema: props.model?.uiSchema
                    });
                    props.onUpdateItem(item);
                    props.onClose(props.index);
                }}>
                    <SaveIcon/> {props.editMode ? "更新" : "新規登録"}
                </Button>
                <Button className="App-Form-Button" onClick={() => {
                    props.onClose(props.index);
                }}><CloseIcon/>Close</Button>
            </DialogActions>
        </Dialog>
    )
};
