import React, {useEffect, useRef, useState} from "react";
import GenericTemplate from "../../templates/GenericTemplate";
import {LinearProgress, Paper} from '@mui/material';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {Edit, Delete, Create} from '@mui/icons-material';
import {Button} from "@mui/material";
import {RadioGroup, Box, IconButton, Typography} from '@mui/material';
import {ItemsPagination} from '../../common/ItemsPagenation'
import {toast} from 'react-toastify';


import {useParams} from "react-router-dom";
import {usePagination} from "../../../hooks/usePagination";
import {JsonTemplateForm} from "./JsonTemplateForm";
import {TModelKey} from "../../../model/baseModel";
import {JsonTemplate, TJsonTemplateParam} from "../../../model/jsonTemplate";
import {normalizedId} from "../helper/formHelper";
import {
    deleteJsonTemplate,
    getJsonTemplateById,
    getJsonTemplatesUri,
    postJsonTemplate,
    putJsonTemplate
} from "../../../modules/acmv3-api/jsonTemplate";

export const JsonTemplatesPage: React.FC = () => {
    const [ parentId, ] = React.useState<string>(useParams().parentId || '');
    const [ fetchUri, setFetchUri ] = useState("");
    const [ currentPageItems, setCurrentPageItems ] = useState<JsonTemplate[]>([])
    const [ newItem, setNewItem ] = useState<JsonTemplate>();
    const openId = useRef<number>(1);

    const handleOpen = (index: number, jsonSchemaId?: string) => {
        console.log(`handleOpen`, {index, jsonSchemaId});
        (jsonSchemaId) && getJsonTemplateById(jsonSchemaId).then(m => {
            const newItems = currentPageItems.map(item => m.id === item.id ? m : item);
            setCurrentPageItems(newItems);
            setDialogOpen(index, true);
        })
    };

    const handleClose = (index: number) => setDialogOpen(index, false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpens, setIsOpens ] = useState(new Array(currentPageItems.length));
    const setDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpens(opens);
    };

    // Data
    const setNewUri = () => {
        getJsonTemplatesUri({
            parentId,
            exclusiveStartKey: keys[currentPageIndex]
        }).then(setFetchUri);
    };

    useEffect(() => {
        setNewUri();
    }, []);

    const {
        isLoading,
        currentPageIndex,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem,
        totalPages,
        keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: e => {
            toast.error(`読み込みに失敗しました + ${e.toString()}`);
        },
        onNewUri: setNewUri
    })

    const onCreateData = (newData: JsonTemplate) => {
        const param: TJsonTemplateParam = {...newData.json(), id: undefined}
        postJsonTemplate(parentId, param).then(r => {
            const newItems = [
                ...currentPageItems,
                new JsonTemplate(r.body)
            ];
            setCurrentPageItems(newItems);
        });
    }

    const onUpdateData = (newItem: JsonTemplate) => {
        putJsonTemplate(newItem).then(() => {
            toast.success('更新に成功しました');
            setCurrentPageItems(currentPageItems.map(item => {
                    return item.parentId === newItem.parentId && item.id === newItem.id ?
                        new JsonTemplate(newItem.json()) : item
                }
            ));
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        });
        const newItems = currentPageItems.map(item => item.id === newItem.id ? new JsonTemplate(newItem.json()) : item);
        setCurrentPageItems(newItems);
    }

    const removeItemHandler = (key: TModelKey) => {
        deleteJsonTemplate(key).then(() => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(item => item.id !== key.id));
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch((e: any) => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    const onChangeJson = (jsonType: 'jsonSchema'|'uiSchema', id: string | undefined, e: React.ChangeEvent<HTMLInputElement>) => {
        (id) && setCurrentPageItems(currentPageItems.map(item => {
                return id === item.id ?
                    new JsonTemplate({...item.json(), [jsonType]: e}) : item
            }
        ));
    }

    const setClose = () => setIsOpen(false);

    return (
        <GenericTemplate title="JSONテンプレート一覧">
            <Typography variant="h5" sx={{m: "1rem"}}>
                JSONテンプレート一覧
            </Typography>
            { isLoading ? ( <LinearProgress/>) : (
                <TableContainer component={Paper}>
                    <div className="App-Content-Menu">
                        <Box>
                            <Button onClick={() => {
                                openId.current = openId.current + 1;
                                setNewItem(new JsonTemplate({parentId}));
                                setIsOpen(true);
                            }}><Create/>新規作成</Button>
                        </Box>
                    </div>

                    <JsonTemplateForm
                        key={openId.current.toString()}
                        isOpen={isOpen}
                        onClose={setClose}
                        onUpdateItem={onCreateData}
                        editMode={false}
                        model={newItem}
                        onChangeJson={f=>f}
                    />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>JSONテンプレート名</TableCell>
                                    <TableCell align="left">Parent</TableCell>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="right"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {currentPageItems.map((model, index) => {
                                    return (
                                        <TableRow key={model.id}>
                                            <TableCell component="th" scope="row">
                                                {model.name}
                                            </TableCell>
                                            <TableCell align="left">{normalizedId(model.parentId)}</TableCell>
                                            <TableCell align="left">{normalizedId(model.id)}</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleOpen(index, model.id)}>
                                                    <Edit/>
                                                </IconButton>
                                                <JsonTemplateForm
                                                    key={model.id}
                                                    model={model}
                                                    isOpen={!!isOpens[index]}
                                                    index={index}
                                                    onClose={() => handleClose(index)}
                                                    onUpdateItem={onUpdateData}
                                                    onChangeJson={onChangeJson}
                                                    editMode={true}
                                                />
                                                <IconButton
                                                    onClick={() => removeItemHandler({
                                                        parentId: model.parentId,
                                                        id: model.id
                                                    })}>
                                                    <Delete/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </RadioGroup>
                </TableContainer>
            )}

            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    );

};
