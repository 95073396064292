import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";

type TJsonTemplateAttributes = {
    name?: string,
    jsonSchema?: string,
    uiSchema?: string
};

export type TJsonTemplate = TBaseModel & TJsonTemplateAttributes;
export type TJsonTemplateParam = TBaseModelParam & TJsonTemplateAttributes;

export class JsonTemplate extends BaseModel {
    name?: string;
    jsonSchema?: any;
    uiSchema?: any;

    constructor(obj: TJsonTemplateParam) {
        super(obj);
        this.name = obj.name;
        this.setAttributes(obj);
    }

    setAttributes(obj: TJsonTemplateParam) {
        this.updateAttributes(obj)
    }

    updateAttributes(obj: TJsonTemplateParam) {
        super.updateAttribute(obj, 'name', '');
        super.updateAttribute(obj, 'jsonSchema', {});
        super.updateAttribute(obj, 'uiSchema', {});
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            name: this.name,
            jsonSchema: this.jsonSchema || {},
            uiSchema: this.uiSchema || {},
            updatedAt: this.updatedAt
        }
    }
}


