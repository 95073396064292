import React, {useState} from "react";
import {Save} from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import {DownloadFile, IDownloadFile} from "../../../model/downloadFile";

interface FileFormProps {
    key?: string,
    model: DownloadFile,
    isOpen: boolean,
    index: number,
    editMode: boolean,
    onUpdateItem: (item: IDownloadFile, file: File) => void,
    onClose: (index: number) => void,
}

export const DownloadFileForm: React.FC<FileFormProps> = (props) => {
    const [ files, setFiles ] = useState<File[]>([]);
    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files) {
            const file = event?.target?.files[0];
            // @ts-ignore
            setFiles([ ...files, ...event.target.files ]);
        }

        event.target.value = "";
    };

    return (
        <Dialog open={props.isOpen} onClose={() => props.onClose(props.index)}>
            <DialogTitle>ファイル{props.editMode ? '編集' : '登録'}</DialogTitle>
            <DialogContent>
                <Button variant="contained" component="label">
                    ファイル選択
                    <input type='file' hidden multiple onChange={onFileInputChange}/>
                </Button>

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ファイル名</TableCell>
                            <TableCell>ファイルタイプ</TableCell>
                            <TableCell>サイズ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map(file => (
                            <TableRow key={file.name}>
                                <TableCell component="th" scope="row">
                                    {file.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {file.type}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                    {file.size.toLocaleString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </DialogContent>
            <DialogActions>
                <Button className="App-Form-Button" onClick={() => {
                    const file = files[0];
                    if ( file ) {
                        const item: IDownloadFile = {
                            name: file.name,
                            contentType: file.type || 'text/plain',
                        };
                        props.onUpdateItem(item, file);
                        props.onClose(props.index);
                    }
                }}>
                    <Save/>アップロード
                </Button>
                <Button onClick={() => props.onClose(props.index)}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
}
