import {TextField} from "@mui/material";
import React from "react";

export interface IFormTextField {
    id: string,
    label: string,
    value:  string | (() => string),
    setProps:  React.Dispatch<React.SetStateAction<string>>
    helperText?: string,
    autofocus?: boolean,
    multiline?: boolean,
    disabled?: boolean,
    error?: boolean
    helperTextIsError?: boolean
}

export const FormTextField = (props: IFormTextField) => {
    return (
        <TextField
            type="text" autoFocus={props.autofocus||false} margin="dense" fullWidth variant="standard"
            label={props.label}
            onChange={e => props.setProps(e.target.value)}
            helperText={props.helperText}
            value={props.value}
            multiline={props.multiline}
            disabled={props.disabled}
            error={props.error || (props.helperTextIsError && (props.helperText as string)?.length > 0) ||false}
        />
    )
}