import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from 'react-toastify';
import {Box, Paper, Button, Typography, IconButton, LinearProgress} from '@mui/material';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {Edit, Delete, Create} from '@mui/icons-material';

import GenericTemplate from "../../templates/GenericTemplate";
import {getProviderAdminsUri, postAdmin, deleteProviderAdmin, putAdmin} from "../../../modules/acmv3-api/admin";
import {usePagination} from "../../../hooks/usePagination";
import {ItemsPagination} from '../../common/ItemsPagenation'
import {ProviderAdminForm} from "./ProviderAdminForm";
import {TModelKey} from "../../../model/baseModel";
import {Admin, TAdmin} from "../../../model/admin";
import {getTenants} from "../../../modules/acmv3-api/tenants";
import {Tenant} from "../../../model/tenant";
import {normalizedId} from "../helper/formHelper";

export const ProviderAdminsPage: React.FC = () => {
    const [parentId,] = React.useState<string>(useParams().parentId || '');
    const [fetchUri, setFetchUri] = useState("");
    const [searchKeyword, ] = useState("");
    const [currentPageItems, setCurrentPageItems] = useState<Admin[]>([])
    const [tenants, setTenants] = useState<Tenant[]>([])
    const openId = useRef<number>(1);

    const handleOpen = (index: number) => setDialogOpen(index, true);
    const handleClose = (index: number) => setDialogOpen(index, false);
    const setDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpens(opens);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpens, setIsOpens] = useState(new Array(currentPageItems.length));
    const [isLoading, setIsLoading] = useState(false);

    const setNewUri = () => {
        setIsLoading(true);
        setFetchUri(getProviderAdminsUri({
            parentId,
            exclusiveStartKey: keys[currentPageIndex],
            namePrefix: (searchKeyword || '').toLowerCase(),
            method: 'GET'
        }))

        getTenants({parentId, method: 'GET'}).then(r => {
            setTenants(r);
        }).finally(()=>setIsLoading(false))
    };

    useEffect(() => {
        setNewUri();
    }, []);

    const getBelongToTenantNames = (adminTenantIds: string[]): string[] => {
        if (!adminTenantIds) return [];
        return (adminTenantIds).map(id => {
            const t = tenants.find(t => t.id === id);
            return t ? t.name : undefined
        }).filter((item): item is NonNullable<typeof item> => item != null);
    }

    const {
        currentPageIndex,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem,
        totalPages,
        keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: e => {
            toast.error(`プロパイダ情報の読み込みに失敗しました + ${e.toString()}`);
        },
        onNewUri: setNewUri
    });
    const setClose = () => setIsOpen(false);

    const onCreateData = (newData: TAdmin) => {
        console.log('ProviderTenantsPage.onCreateData', newData)
        postAdmin({parentId, item: newData}).then(r => {
            console.log(r);
            if (r.fetchResult.ok) {
                setCurrentPageItems([
                    ...currentPageItems,
                    new Admin(r.body)
                ]);
            } else {
                console.error(`ProviderAdminsPage.onCreateData Error:`, r.body)
                let errorMessage = '';
                if (r.body.error) {
                    const resError = JSON.parse(r.body.error)
                    console.error(`ProviderAdminsPage.onCreateData resError:`, resError)
                    if (resError.response.code === "UsernameExistsException") {
                        errorMessage = 'すでにユーザーが存在しています';
                    } else {
                        errorMessage = resError.response.message;
                    }
                }
                toast.error(`新規登録に失敗しました ${errorMessage ? '(' + errorMessage + ')' : ''}`);
            }
        }).catch(e => {
            console.error(`ProviderAdminsPage.onCreateData Error:`, JSON.stringify(e))
            toast.error(`新規登録に失敗しました + ${e.toString()}`);
        });
    }

    const onUpdateData = (item: TAdmin) => {
        putAdmin(item).then(r => {
            toast.success('更新に成功しました');
            setCurrentPageItems(currentPageItems.map(o => {
                    return (r.body && r.body.id === o.id) ? new Admin(r.body) : o
                }
            ));
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        });
    }

    const removeItemHandler = (key: TModelKey) => {
        // @ts-ignore
        deleteProviderAdmin(key).then(r => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(tenant => tenant.id !== key.id));
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch(e => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    return (
        <GenericTemplate title="管理者一覧">
            <Typography variant="h5" sx={{m: "1rem"}}>
                管理者一覧
            </Typography>

            { isLoading ? ( <LinearProgress/>) : (
                <TableContainer component={Paper}>
                    <div className="App-Content-Menu">
                        <Box>
                            <Button onClick={() => {
                                setIsOpen(true);
                                openId.current = openId.current + 1;
                            }}><Create/>新規作成</Button>
                        </Box>
                    </div>

                    <ProviderAdminForm
                        key={openId.current.toString()}
                        parentId={parentId}
                        tenants={tenants}
                        isOpen={isOpen} onClose={setClose}
                        onUpdateItem={onCreateData}
                        editMode={false}
                    />

                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>管理者名</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell align="left">Parent</TableCell>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentPageItems.map((model, index) => {
                                // Calculate initial value of Tenant name to which Admin belongs from tenant's id array
                                const adminTenantNames = getBelongToTenantNames(model.adminTenantIds);
                                return (
                                    <TableRow key={model.id}>
                                        <TableCell component="th" scope="row">{model.name}</TableCell>
                                        <TableCell align="left">{model.email}</TableCell>
                                        <TableCell align="left">{normalizedId(model.parentId)}</TableCell>
                                        <TableCell align="left">{normalizedId(model.id)}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => {
                                                handleOpen(index)
                                            }}>
                                                <Edit/>
                                            </IconButton>
                                            <ProviderAdminForm
                                                key={model.id}
                                                tenants={tenants}
                                                model={model}
                                                parentId={parentId}
                                                adminTenantNames={adminTenantNames}
                                                isOpen={isOpens[index]}
                                                index={index}
                                                onClose={() => handleClose(index)}
                                                onUpdateItem={onUpdateData}
                                                editMode={true}
                                            />
                                            <IconButton
                                                onClick={() => removeItemHandler({parentId: model.parentId, id: model.id})}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    )
}
