import {API_BASE_URL} from "../acmv3-api";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";

export const getDownloadFilesUri = (param: TGetItemsUrlParam) => {
    return `${API_BASE_URL}/tenants/${param.parentId}/download-files${getItemsUrlParameter(param)}`
}

export const getDownloadFiles = async (param: TGetItemsUrlParam): Promise<any[]> => {
    console.log(`parentId: ${param.parentId}`)
    const url = getDownloadFilesUri({...param, ...{method: 'GET'}});
    const res = await fetch(url, await getFetchOption());
    const json = await res.json();
    console.log('getDownloadFiles', json)
    return json;
};

export const deleteDownloadFile = async (tenantId: string, fileName: string) => {
    const param: TGetItemsUrlParam = {
        parentId: tenantId,
        method: 'DELETE',
    }
    const url = getDownloadFilesUri(param) + `/${fileName}`;
    console.log(`deleteDownloadFile: url: ${url}`);
    const fetchResult = await fetch(url, await getFetchOption({method: 'DELETE'}));
    console.log('deleteDownloadFile', fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
}

export const getPutDownloadFileUrl = async (tenantId: string,
                                            fileParam: { fileName: string, contentType: string }) => {
    const method = 'POST';
    const uriParam: TGetItemsUrlParam = {
        method,
        parentId: tenantId,
    }
    const url = getDownloadFilesUri({...uriParam, ...{method}}) + "/pre-signed-url/put";
    const option: TGetFetchOptionParam = {
        method,
        body: fileParam
    }
    const fetchResult = await fetch(url, await getFetchOption(option))
    return {body: await fetchResult.json(), fetchResult};
}