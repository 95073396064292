import {API_BASE_URL} from "../acmv3-api";
import {TModelKey} from "../../model/baseModel";
import {TUser, TUserParam, User} from "../../model/user";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";
import {UserQrDialogCallbackParam} from "../../components/pages/users/UserQrDialog";
import {getTenantByIdUri} from "./tenants";

export const getUsersUri = (param: TGetItemsUrlParam) => {
    return `${API_BASE_URL}/tenants/${param.parentId}/users` + getItemsUrlParameter(param);
}

const getUserUri = (key: TModelKey) => {
    return `${API_BASE_URL}/tenants/${key.parentId}/users/${key.id}`;
}

export const getUsers = async (param: TGetItemsUrlParam): Promise<any[]> => {
    console.log(`parentId: ${param.parentId}`)
    const url = getUsersUri({...param, ...{method: 'GET'}});
    const res = await fetch(url, await getFetchOption());
    const json = await res.json();
    console.log('getUsers', json)
    return json;
};

export const postUser = async (args: { parentId: string, item: TUser }) => {
    console.log(`postUser: item: ${JSON.stringify(args.item)}`);
    const param: TUserParam = {
        parentId: args.parentId,
        name: args.item.name,
        loginId: args.item.loginId,
        temporaryPassword: args.item.temporaryPassword,
        jsonUser: args.item.jsonUser
    };

    const model = new User(param);
    const method = 'POST';
    console.log(`postUser: model.json: ${JSON.stringify(model.json())}`);
    const postParam: TGetItemsUrlParam = {
        parentId: args.parentId,
        method,
    }

    let fetchResult: Response|undefined = undefined;
    try {
        fetchResult = await fetch(getUsersUri(postParam),
            await getFetchOption({method, body: model.json()}));
        return {body: await fetchResult.json(), fetchResult};
    } catch (e: any) {
        return {body: {error: e.toString()}, fetchResult};
    }

}

export const putUser = async (item: TUser) => {
    console.log(`putUser: item: ${JSON.stringify(item)}`);
    const model = new User(item);
    const option: TGetFetchOptionParam = {method: 'PUT', body: model.json()};
    console.log(`putUser: url: ${getUserUri(model.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getUserUri(model.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
}

export const deleteUser = async (key: TModelKey) => {
    console.log(`deleteUser: url: ${getUserUri(key)}}`);
    const fetchResult = await fetch(getUserUri(key), await getFetchOption({method: "DELETE"}));
    console.log('deleteUser', fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
}

export const userQrDownload = async(param: UserQrDialogCallbackParam) => {
    const fetchResult = await fetch(`${getTenantByIdUri(param.tenantId)}/qr`,
        await getFetchOption({method: 'POST', body: param}));
    console.log('userQrDownload fetchResult', await fetchResult)
    return {
        ok: httpResponseCodeIsSuccess(fetchResult.status),
        result: await fetchResult.json()
    };
}