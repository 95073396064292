import React, {useEffect, useRef, useState} from "react";
import GenericTemplate from "../../templates/GenericTemplate";
import {LinearProgress, Paper} from '@mui/material';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {Edit, Delete, Create, SupervisedUserCircle, HolidayVillage} from '@mui/icons-material';
import {Button} from "@mui/material";
import {Box, IconButton, Typography} from '@mui/material';
import {ItemsPagination} from '../../common/ItemsPagenation'
import {toast} from 'react-toastify';

import {
    deleteProvider, getProvidersUri, postProvider, putProvider,
} from "../../../modules/acmv3-api";

import {useParams} from "react-router-dom";
import {usePagination} from "../../../hooks/usePagination";
import {Provider, TProvider} from "../../../model/provider";
import {ProviderForm} from "./ProviderForm";
import {IModelKey} from "../../../model/baseModel";
import {Link} from "react-router-dom";
import {normalizedId} from "../helper/formHelper";

const createData = (provider: Provider) => {
    return {id: provider.id, name: provider.name};
};

interface ProviderItemProps {
    isOpen: boolean,
    model: Provider,
    index: number,
    handleClose: (index: number) => void,
    updateModel?: (newData: TProvider) => void,
}

export const ProvidersPage: React.FC = () => {
    const [ parentId, ] = React.useState<string>(useParams().parentId || '');
    const [ fetchUri, setFetchUri ] = useState("");
    const [ searchKeyword, ] = useState("");

    const [ currentPageItems, setCurrentPageItems ] = useState<Provider[]>([])
    const openId = useRef<number>(1);

    const handleOpen = (index: number) => setDialogOpen(index, true);
    const handleClose = (index: number) => setDialogOpen(index, false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpens, setIsOpens ] = useState(new Array(currentPageItems.length));
    const setDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpens(opens);
    };

    // Data
    const setNewUri = () => {
        getProvidersUri({
            parentId,
            exclusiveStartKey: keys[currentPageIndex], namePrefix: (searchKeyword || '').toLowerCase()
        }).then(setFetchUri);
    };

    useEffect(() => {
        setNewUri();
    }, []);

    const {
        isLoading,
        currentPageIndex,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem,
        totalPages,
        keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: e => {
            toast.error(`プロパイダ一覧情報の読み込みに失敗しました :${e.toString()}`);
        },
        onNewUri: setNewUri
    })

    const onCreateData = (newData: TProvider) => {
        console.log('onCreateData')
        console.log(newData)
        postProvider({parentId, createItem: newData}).then(r => {
            const newItems = [
                ...currentPageItems,
                new Provider(r.body)
            ];
            setCurrentPageItems(newItems);
        });
    }

    const onUpdateData = (newItem: TProvider) => {
        putProvider(newItem).then(() => {
            toast.success('更新に成功しました');
            setCurrentPageItems(currentPageItems.map(item => {
                    return item.parentId === newItem.parentId && item.id === newItem.id ?
                        new Provider(newItem) : item
                }
            ));
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        });
        const newItems = currentPageItems.map(item => item.id === newItem.id ? new Provider(newItem) : item);
        setCurrentPageItems(newItems);
    }

    const removeItemHandler = (key: IModelKey) => {
        // @ts-ignore
        deleteProvider(key).then(r => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(provider => provider.id !== key.id));
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch(e => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    const setClose = () => setIsOpen(false);

    const TableProvider = (props: any) => {
        return (
            <TableContainer component={Paper}>
                <div className="App-Content-Menu">
                    <Box>
                        {/*    <TextSearchField onSearch={setSearchKeyword}/>*/}
                        <Button onClick={() => {
                            setIsOpen(true);
                            openId.current = openId.current + 1;
                        }}><Create/>新規作成</Button>
                    </Box>
                </div>

                <ProviderForm
                    key={openId.current.toString()}
                    isOpen={isOpen}
                    onClose={setClose}
                    onUpdateItem={onCreateData}
                    editMode={false}
                    model={new Provider({name: ''})}
                />

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>プロパイダ名</TableCell>
                            <TableCell align="left">Parent</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="right">最大ユーザー数</TableCell>
                            <TableCell align="right">テナント</TableCell>
                            <TableCell align="right">管理者</TableCell>
                            <TableCell align="right">編集</TableCell>
                            <TableCell align="right">削除</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items.map((model: Provider, index: number) => {
                            return (
                                <TableRow key={model.id}>
                                    <TableCell component="th" scope="row">{model.name}</TableCell>
                                    <TableCell align="left">{normalizedId(model.parentId)}</TableCell>
                                    <TableCell align="left">{normalizedId(model.id)}</TableCell>
                                    <TableCell align="right">{model.allowTenantMaxUser}</TableCell>
                                    <TableCell align="right">

                                        <IconButton component={Link} to={`/providers/${model.id}/tenants`}>
                                            <HolidayVillage/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton component={Link} to={`/providers/${model.id}/admins`}>
                                            <SupervisedUserCircle/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleOpen(index)}>
                                            <Edit/>
                                        </IconButton>
                                        <ProviderForm
                                            key={model.id}
                                            model={model}
                                            isOpen={isOpens[index]}
                                            index={index}
                                            onClose={() => handleClose(index)}
                                            onUpdateItem={onUpdateData}
                                            editMode={true}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => removeItemHandler({parentId: model.parentId, id: model.id})}>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    let children: JSX.Element;
    if ( isLoading ) {
        children = <LinearProgress/>
    } else {
        children = <TableProvider items={currentPageItems}/>
    }

    return (
        <GenericTemplate title="プロパイダ一覧">
            <Typography variant="h5" sx={{m: "1rem"}}>
                プロパイダ一覧
            </Typography>
            {children}
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    );

};
