import React, {useState} from "react";
import {Button, Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DatePickerDay from "../../common/DatePickerDay";
import Container from "@mui/material/Container";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {addMonths, endOfMonth, format} from 'date-fns'

interface UserQrDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    tenantId: string;
    userIds: string[];
    onQrCreateButton: (param: UserQrDialogCallbackParam) => void;
}

export interface UserQrDialogCallbackParam {
    tenantId: string;
    userIds: string[];
    prefix: string,
    startDate: string;
    endDate: string;
}

export const UserQrDialog: React.FC<UserQrDialogProps> = (props) => {
    const startDateFormat = format(new Date(), 'yyyy/MM/dd');
    const endDateFormat = format(endOfMonth(addMonths(new Date(), + 1)), 'yyyy/MM/dd');
    const [ startDate, setStartDate ] = useState(startDateFormat);
    const [ endDate, setEndDate ] = useState(endDateFormat);
    const [ checkEnableExpiration, setCheckEnableExpiration ] = useState(false);

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={props.isOpen} onClose={() => {
            props.setIsOpen(false)
        }} scroll="body">
            <DialogTitle>QR コード作成</DialogTitle>
            <DialogContent>
                <Container sx={{mt: 1, mb: 1}}>
                    <FormGroup>
                        <FormControlLabel label="有効期限を指定" control={
                            <Checkbox
                                onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                                    setCheckEnableExpiration(e.target.checked)
                                }}
                                checked={checkEnableExpiration}/>
                        }/>
                    </FormGroup>
                    <DatePickerDay disabled={!checkEnableExpiration} label={'有効期限: 開始日時'} value={startDate}
                                   setProps={setStartDate}/>
                    <DatePickerDay disabled={!checkEnableExpiration} label={'有効期限: 終了日時'} value={endDate}
                                   setProps={setEndDate}/>
                </Container>
                <Container>
                    <Button disabled={props.userIds.length === 0} onClick={() => {
                        props.onQrCreateButton({
                            startDate: checkEnableExpiration ? startDate : "",
                            endDate: checkEnableExpiration ? endDate : "",
                            tenantId: props.tenantId,
                            userIds: props.userIds,
                            prefix: 'agesetup1://ACE1'
                        })
                    }}>Create</Button>
                    <Button onClick={() => {
                        props.setIsOpen(false);
                    }}>Close</Button>
                </Container>
            </DialogContent>
        </Dialog>
    );
}
