import {Pagination} from "@aws-amplify/ui-react";

export interface IItemsPaginationProps {
    itemCount?: number,
    currentPageNo?: number,
    totalPages: number,
    onNext?: () => void,
    onPrevious?: () => void,
    onChange?: () => void,
}

export const ItemsPagination = (props: IItemsPaginationProps)  => {
    if (!props.itemCount) return <div/>
    return <Pagination
        currentPage={props.currentPageNo}
        totalPages={props.totalPages}
        siblingCount={3}
        onNext={props.onNext}
        onPrevious={props.onPrevious}
        onChange={props.onChange}
    />
};
