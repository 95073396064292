import {JsonTemplate, TJsonTemplateParam} from "../../model/jsonTemplate";
import {API_BASE_URL} from "../acmv3-api";
import {getFetchOption, httpResponseCodeIsSuccess, TGetFetchOptionParam} from "../acmv3-api-ts";
import {TModelKey} from "../../model/baseModel";
import {Auth} from "aws-amplify";

const getJsonTemplateUri = (key: TModelKey) => {
    return `${API_BASE_URL}/providers/${key.parentId}/json-templates/${key.id}`;
};

type TGetJsonTemplatesUri = {
    parentId?: string,
    exclusiveStartKey?: string,
    namePrefix?: string
}
export const getJsonTemplatesUri = async (key: TGetJsonTemplatesUri = {}) => {
    let {parentId, exclusiveStartKey, namePrefix} = key;
    if (!parentId) {
        const user = await Auth.currentAuthenticatedUser();
        parentId = user.attributes['custom:providerId'];
    }
    if ( !parentId ) {
        throw new Error('Error parentId is undefined')
    }
    let uri = `${API_BASE_URL}/providers/${parentId}/json-templates?perPage=15`;
    if (exclusiveStartKey) {
        uri += `&exclusiveStartKey=${exclusiveStartKey}`;
    }
    if (namePrefix) {
        uri += `&name=${namePrefix}`;
    }
    return uri;
}

const getJsonTemplateByIdUri = (id: string) => {
    return `${API_BASE_URL}/json-templates/${id}`;
};

// export const getJsonTemplates = async ({parentId, exclusiveStartKey, namePrefix}: TGetJsonTemplatesUri) => {
export const getJsonTemplates = async (key?: TGetJsonTemplatesUri) => {
    const url = await getJsonTemplatesUri(key);
    const res = await fetch(url, await getFetchOption());
    const json = await res.json();
    console.log(json)
    return json;
};

export const getJsonTemplate = async ({parentId, id}: TModelKey) => {
    const res = await fetch(getJsonTemplateUri({parentId, id}), await getFetchOption());
    return new JsonTemplate(await res.json());
};

export const getJsonTemplateById = async (jsonTemplateId: string) => {
    const res = await fetch(getJsonTemplateByIdUri(jsonTemplateId), await getFetchOption());
    return new JsonTemplate(await res.json());
};

export const postJsonTemplate = async (parentId: string, createItem: TJsonTemplateParam) => {
    const model = new JsonTemplate({...createItem, ...{id: ''}});
    const method = 'POST';
    const fetchResult = await fetch(await getJsonTemplatesUri({parentId}),
        await getFetchOption({method, body: model.json()}));
    const body = await fetchResult.json();

    return {body, fetchResult};
};

export const putJsonTemplate = async (model: JsonTemplate) => {
    const method = 'PUT';
    const option: TGetFetchOptionParam = {method, body: model.json()};
    console.log(`putJsonTemplate: url: ${getJsonTemplateUri(model.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getJsonTemplateUri(model.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
};

export const deleteJsonTemplate = async (key: TModelKey) => {
    const method = 'DELETE';
    const option: TGetFetchOptionParam = {method};
    console.log(`deleteJsonTemplate: url: ${key} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getJsonTemplateUri(key), await getFetchOption(option));
    console.log(fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
};