import BaseModel, {TBaseModelParam} from "./baseModel";
import {TBaseModel} from "./baseModel";
import {Tenant} from "./tenant";

type TAdminAttributes = {
    name: string,
    temporaryPassword: string
    email: string,
    adminTenantIds: string[],
    adminProviderId?: string
}

export type TAdmin =  TBaseModel & TAdminAttributes;
export type TAdminParam =  TBaseModelParam & TAdminAttributes;

export class Admin extends BaseModel {
    name: string;
    email: string;
    temporaryPassword: string;
    adminTenantIds: string[];
    adminProviderId?: string;

    constructor(item: TAdminParam) {
        super(item);
        this.name = item.name
        this.email = item.email
        this.temporaryPassword = item.temporaryPassword
        this.adminTenantIds = item.adminTenantIds
        this.adminProviderId = item.adminProviderId
    }

    updateAttributes(item: TAdmin) {
        item.name !== undefined && (this.name = item.name)
        item.email !== undefined && (this.email = item.email)
        item.temporaryPassword !== undefined && (this.temporaryPassword = item.temporaryPassword)
        item.adminTenantIds !== undefined && (this.adminTenantIds = item.adminTenantIds)
        item.adminProviderId !== undefined && (this.adminProviderId = item.adminProviderId)
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            phoneticKey: this.phoneticKey,
            name: this.name,
            email: this.email,
            temporaryPassword: this.temporaryPassword,
            adminTenantIds: this.adminTenantIds,
            adminProviderId: this.adminProviderId,
            updatedAt: this.updatedAt
        }
    }

    getBelongToTenantNames(tenants: Tenant[]): string[] {
        return (this.adminTenantIds).map(id => {
            const t = tenants.find(t=> t.id === id);
            return t ? t.name : undefined
        }).filter((item): item is NonNullable<typeof item> => item != null);
    }
}


