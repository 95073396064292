import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {toast} from 'react-toastify';
import {Box, Paper, Button, Typography, IconButton, LinearProgress} from '@mui/material';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {Delete, Create, Sip, Person, UploadFile, Settings} from '@mui/icons-material';

import GenericTemplate from "../../templates/GenericTemplate";
import {getTenantsUri, postTenant, deleteTenant, putTenant} from "../../../modules/acmv3-api/tenants"
import {usePagination} from "../../../hooks/usePagination";
import {TTenant, Tenant, TTenantParam} from "../../../model/tenant";
import {ItemsPagination} from '../../common/ItemsPagenation'
import {ProviderTenantForm} from "./ProviderTenantForm";
import {TModelKey} from "../../../model/baseModel";
import {normalizedId} from "../helper/formHelper";

export const ProviderTenantsPage: React.FC = () => {
    const [ parentId, ] = React.useState<string>(useParams().parentId || '');
    const [ fetchUri, setFetchUri ] = useState("");
    const [ searchKeyword, ] = useState("");
    const [ currentPageItems, setCurrentPageItems ] = useState<Tenant[]>([])
    const openId = useRef<number>(1);

    const handleOpen = (index: number) => setDialogOpen(index, true);
    const handleClose = (index: number) => setDialogOpen(index, false);
    const setDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpens(opens);
    };

    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpens, setIsOpens ] = useState(new Array(currentPageItems.length));

    const setNewUri = () => {
        setFetchUri(getTenantsUri({
            parentId,
            exclusiveStartKey: keys[currentPageIndex], namePrefix: (searchKeyword || '').toLowerCase(),
            method: 'GET'
        }))
    };

    useEffect(() => {
        setNewUri();
    }, []);

    const {
        isLoading,
        currentPageIndex,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem
        ,
        totalPages,
        keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: e => {
            toast.error(`読み込みに失敗しました + ${e.toString()}`);
        },
        onNewUri: setNewUri
    });
    const setClose = () => setIsOpen(false);

    const onCreateData = (newData: TTenantParam) => {
        postTenant({parentId, createItem: newData}).then(r => {
            if (r.fetchResult.ok) {
                toast.success('新規登録に成功しました');
                setCurrentPageItems([
                    ...currentPageItems,
                    new Tenant(r.body)
                ]);
            } else {
                const message = r.body?.error === "Not configured this API"
                    ? "入力された 「+電話帳v3 API Host」 は登録されていません"
                    : "新規登録に失敗しました (${r.fetchResult.status}:${r.fetchResult.statusText} ${r.body?.error})";
                toast.error(message);
            }
        }).catch(e => {
            toast.error(`新規登録に失敗しました + ${e.toString()}`);
        });
    }

    const onUpdateData = (newItem: TTenant) => {
        putTenant(newItem).then(() => {
            toast.success('更新に成功しました');
            setCurrentPageItems(currentPageItems.map(item => {
                    return item.parentId === newItem.parentId && item.id === newItem.id ?
                        new Tenant(newItem) : item
                }
            ));
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        });
        const newItems = currentPageItems.map(item => item.id === newItem.id ? new Tenant(newItem) : item);
        setCurrentPageItems(newItems);
    }

    const removeItemHandler = (key: TModelKey) => {
        // @ts-ignore
        deleteTenant(key).then(r => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(tenant => tenant.id !== key.id));
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch(e => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    return (
        <GenericTemplate title="テナント一覧">
            <Typography variant="h5" sx={{m: "1rem"}}>
                テナント一覧
            </Typography>

            {isLoading ? (<LinearProgress/>) : (<TableContainer component={Paper}>
                    <div className="App-Content-Menu">
                        <Box>
                            {/*    <TextSearchField onSearch={setSearchKeyword}/>*/}
                            <Button onClick={() => {
                                setIsOpen(true);
                                openId.current = openId.current + 1;
                            }}><Create/>New</Button>
                        </Box>
                    </div>

                    <ProviderTenantForm
                        key={openId.current.toString()}
                        isOpen={isOpen} onClose={setClose}
                        onCreateItem={onCreateData}
                        editMode={false}
                        model={new Tenant({name: ''})}
                    />

                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">テナント名</TableCell>
                                <TableCell align="left">テナントID</TableCell>
                                <TableCell align="left">Parent</TableCell>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="center">編集</TableCell>
                                <TableCell align="center">サーバー<br/>設定</TableCell>
                                <TableCell align="center">ユーザー<br/>設定</TableCell>
                                <TableCell align="center">ファイル<br/>登録</TableCell>
                                <TableCell align="center">削除</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentPageItems.map((model, index) => {
                                return (
                                    <TableRow key={model.id}>
                                        <TableCell component="th" scope="row">
                                            {model.name}
                                        </TableCell>
                                        <TableCell align="left">{model.tenantId}</TableCell>
                                        <TableCell align="left">{normalizedId(model.parentId)}</TableCell>
                                        <TableCell align="left">{normalizedId(model.id)}</TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => handleOpen(index)}>
                                                <Settings/>
                                            </IconButton>
                                            <ProviderTenantForm
                                                key={model.id}
                                                model={model}
                                                isOpen={isOpens[index]}
                                                index={index}
                                                onClose={() => handleClose(index)}
                                                onUpdateItem={onUpdateData}
                                                editMode={true}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton component={Link} to={`/tenants/${model.id}/template`}>
                                                <Sip/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton component={Link} to={`/tenants/${model.id}/users`}>
                                                <Person/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton component={Link} to={`/tenants/${model.id}/download-files`}>
                                                <UploadFile/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => removeItemHandler({
                                                    parentId: model.parentId,
                                                    id: model.id
                                                })}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    )
}
