// @ts-ignore
import {JsonEditor as Editor, Mode} from 'jsoneditor-react';
// https://github.com/vankop/jsoneditor-react/blob/master/src/Editor.jsx

import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import 'jsoneditor-react/es/editor.min.css';
import React, {useRef, useState} from "react";

export type JsonEditorProps = {
    name: string;
    value?: any;
    schema?: any;
    mode?: Mode;
    onChange?: (e: any) => void;
    onError?: (e: any) => void;
    onModeChange?: (e: any) => void;
    onFocus?: (e: FocusEvent) => void;
    onBlur?: (e: FocusEvent) => void;
    setEditorRef?: (e: any) => void;
}

export const JsonEditor: React.FC<JsonEditorProps> = (props) => {
    const [htmlElementProps] = useState({style: {height: 900}});
    const defaultAllowedModes: Mode[] = ['tree', 'code'];

    return (
        <Editor
            value={props.value}
            schema={props.schema}
            mode={props.mode || 'code'}
            allowedModes={defaultAllowedModes}
            ace={ace}
            onChange={(e: any) => {
                props.onChange && props.onChange(e)
            }}
            onError={(e: any) => {
                props.onError && props.onError(e)
            }}
            onModeChange={(e: any) => {
                props.onModeChange && props.onModeChange(e)
            }}
            htmlElementProps={htmlElementProps}
            ref={(instance: any) => {
                props.setEditorRef && props.setEditorRef(instance)
            }}
            onFocus={(e: FocusEvent) => {
                props.onFocus && props.onFocus(e)
            }}
            onBlur={(e: FocusEvent) => {
                props.onBlur && props.onBlur(e)
            }}
        />
    )
}