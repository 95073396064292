import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Link} from "react-router-dom";
import {TModelKey} from "../../model/baseModel";
import {Business, SupervisedUserCircle} from "@mui/icons-material";

const UserMenuListItem = (tenantId: string | undefined) => {
    return (
        <>
            <ListItemButton component={Link} to={`/tenants/${tenantId}/template`}>
                <ListItemIcon>
                    <ArticleIcon/>
                </ListItemIcon>
                <ListItemText primary="テンプレート"/>
            </ListItemButton>
            <ListItemButton component={Link} to={`/tenants/${tenantId}/users`}>
                <ListItemIcon>
                    <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary="ユーザー"/>
            </ListItemButton>
        </>
    )
}

export const mainMenuListItems = (userProviderId: string | undefined, tenantId: string | undefined) => {
    return <React.Fragment>
        <ListItemButton component={Link} to="/">
            <ListItemIcon>
                <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="トップページ"/>
        </ListItemButton>
        {userProviderId && (
            <ListItemButton component={Link} to="/providers/">
                <ListItemIcon>
                    <Business/>
                </ListItemIcon>
                <ListItemText primary="プロパイダ"/>
            </ListItemButton>
        )}
        {userProviderId && (
            <ListItemButton component={Link} to={`/providers/${userProviderId}/admins`}>
                <ListItemIcon>
                    <SupervisedUserCircle/>
                </ListItemIcon>
                <ListItemText primary="管理者"/>
            </ListItemButton>
        )}
        {userProviderId && (
            <ListItemButton component={Link} to={`/providers/${userProviderId}/json-templates`}>
                <ListItemIcon>
                    <ArticleIcon/>
                </ListItemIcon>
                <ListItemText primary="JSONテンプレート"/>
            </ListItemButton>
        )}
        {userProviderId && (
            <ListItemButton component={Link} to={`/providers/${userProviderId}/reg-agents`}>
                <ListItemIcon>
                    <ArticleIcon/>
                </ListItemIcon>
                <ListItemText primary="+PUSH REG Agents"/>
            </ListItemButton>
        )}
        {!userProviderId && tenantId && (
            <ListItemButton component={Link} to={`/tenants/${tenantId}/template`}>
                <ListItemIcon>
                    <ArticleIcon/>
                </ListItemIcon>
                <ListItemText primary="テナント"/>
            </ListItemButton>
        )}
        {!userProviderId && tenantId && (
            <ListItemButton component={Link} to={`/tenants/${tenantId}/users`}>
                <ListItemIcon>
                    <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary="ユーザー"/>
            </ListItemButton>
        )}
        {!userProviderId && tenantId && (
            <ListItemButton component={Link} to={`/tenants/${tenantId}/download-files`}>
                <ListItemIcon>
                    <UploadFileIcon/>
                </ListItemIcon>
                <ListItemText primary="ファイル登録"/>
            </ListItemButton>
        )}
    </React.Fragment>
};

interface SecondaryListItemsProps {
    signOut: () => void
}

export const SecondaryMenuListItems: React.FC<SecondaryListItemsProps> = (props) => {
    return (
        <React.Fragment>
            <ListItemButton onClick={props.signOut}>
                <ListItemIcon>
                    <LogoutIcon/>
                </ListItemIcon>
                <ListItemText primary="サインアウト"/>
            </ListItemButton>
        </React.Fragment>
    )
}

