import {API_BASE_URL} from "../acmv3-api";
import {TModelKey} from "../../model/baseModel";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";
import {RegAgentServer, TRegAgentServer, TRegAgentServerParam} from "../../model/regAgentServer";
import {Auth} from "aws-amplify";

type TGetRegAgentServersUri = {
    parentId?: string,
    exclusiveStartKey?: string,
    namePrefix?: string
}
export const getRegAgentServersUri = async (key: TGetRegAgentServersUri = {}) => {
    let {parentId, exclusiveStartKey, namePrefix} = key;
    if (!parentId) {
        const user = await Auth.currentAuthenticatedUser();
        parentId = user.attributes['custom:providerId'];
    }
    if ( !parentId ) {
        throw new Error('Error parentId is undefined')
    }
    let uri = `${API_BASE_URL}/providers/${parentId}/reg-agents?perPage=15`;
    if (exclusiveStartKey) {
        uri += `&exclusiveStartKey=${exclusiveStartKey}`;
    }
    if (namePrefix) {
        uri += `&name=${namePrefix}`;
    }
    return uri;
}

// export const getRegAgentServersUri = (param: TGetItemsUrlParam) => {
//     return `${API_BASE_URL}/providers/${param.parentId}/servers` + getItemsUrlParameter(param);
// }

const getRegAgentServerUri = (key: TModelKey) => {
    return `${API_BASE_URL}/providers/${key.parentId}/reg-agents/${key.id}`;
}

// const getAdminUri = (adminId: string) => {
//     return `${API_BASE_URL}/admins/${adminId}`;
// }

// export const getProviderAdmins = async (param: TGetItemsUrlParam): Promise<any[]> => {
//     console.log(`getProviderAdmins parentId: ${param.parentId}`)
//     const url = getRegAgentServersUri({...param, ...{method: 'GET'}});
//     const res = await fetch(url, await getFetchOption());
//     const json = await res.json();
//     console.log('getUsers', json)
//     return json;
// };

// export const postJsonTemplate = async (parentId: string, createItem: TJsonTemplateParam) => {
//     const model = new JsonTemplate({...createItem, ...{id: ''}});
//     const method = 'POST';
//     const fetchResult = await fetch(await getJsonTemplatesUri({parentId}),
//         await getFetchOption({method, body: model.json()}));
//     const body = await fetchResult.json();
//
//     return {body, fetchResult};
// };

export const postRegAgentServer = async (args: { parentId: string, item: TRegAgentServer }) => {

    const param: TRegAgentServerParam = {
        parentId: args.parentId,
        name: args.item.name,
        maxSlot: args.item.maxSlot,
        pushRate: args.item.pushRate
    };

    const model = new RegAgentServer(param);
    const method = 'POST';
    const postParam: TGetItemsUrlParam = {
        parentId: args.parentId,
        method,
    }
    const fetchResult = await fetch(await getRegAgentServersUri(postParam),
        await getFetchOption({method, body: model.json()}));
    console.log('postRegAgentServer fetchResult', await fetchResult)

    return {body: await fetchResult.json(), fetchResult};
}

export const putRegAgentServer = async (item: TRegAgentServer) => {
    console.log(`putAdmin: item: ${JSON.stringify(item)}`);
    const model = new RegAgentServer(item);
    const method = 'PUT';
    const option: TGetFetchOptionParam = {method, body: model.json()};
    console.log(`putAdmin: url: ${getRegAgentServerUri(model.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getRegAgentServerUri(model.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
}

export const deleteRegAgentServer = async (key: TModelKey) => {
    const method = 'DELETE';
    const option: TGetFetchOptionParam = {method};
    console.log(`deleteRegAgentServer: url: ${getRegAgentServerUri(key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getRegAgentServerUri(key), await getFetchOption(option));
    console.log('deleteRegAgentServer', fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
}
