import {API_BASE_URL} from "../acmv3-api";
import {
    getFetchOption,
    getItemsUrlParameter,
    httpResponseCodeIsSuccess,
    TGetFetchOptionParam,
    TGetItemsUrlParam
} from "../acmv3-api-ts";
import {TModelKey} from "../../model/baseModel";
import {TTenant, Tenant, TTenantParam} from "../../model/tenant";

export const getTenantsUri = (param: TGetItemsUrlParam|TModelKey) => {
    return `${API_BASE_URL}/providers/${param.parentId}/tenants` + getItemsUrlParameter(param);
}

export const getTenantUri = (param: TModelKey) => {
    return `${API_BASE_URL}/providers/${param.parentId}/tenants/${param.id}`;
};
export const getTenantByIdUri = (tenantId = {}) => `${API_BASE_URL}/tenants/${tenantId}`;

export const getTenants = async (param: TGetItemsUrlParam) => {
    // console.log(`tenants.getTenants.parentId: ${param.parentId}`)
    const url = getTenantsUri(param);
    const res = await fetch(url, await getFetchOption());
    // return res.json();
    const items = await res.json();
    return items.Items.map((item: any) => {
        return new Tenant(item);
    });
};

export const getTenant = async (param: TModelKey) => {
    const res = await fetch(getTenantUri(param), await getFetchOption());
    return new Tenant(await res.json());
};

export const getTenantById = async (tenantId: string) => {
    const res = await fetch(getTenantByIdUri(tenantId), await getFetchOption());
    if ( httpResponseCodeIsSuccess(res.status) ) {
        return new Tenant(await res.json());
    } else {
        throw new Error(`${res.status}: ${res.statusText}`);
    }
};

export const postTenant = async (param: {parentId: string, createItem: TTenantParam}) => {
    // console.log(`postTenant: tenantData: ${JSON.stringify(param.createItem)}`);

    const tenant = new Tenant({...param.createItem, ...{parentId: param.parentId}});
    const method = 'POST';
    // console.log(`postTenant: tenant.json: ${JSON.stringify(tenant.json())}`);
    const fetchResult = await fetch(getTenantsUri({parentId: tenant.key.parentId, id:tenant.key.id}),
        await getFetchOption({method, body: tenant.json()}));
    // console.log(tenant.json())
    // console.log(fetchResult)

    return {body: await fetchResult.json(), fetchResult};
};

export const putTenant = async (tenantData: TTenant) => {
    // console.log(`putTenant: tenantData: ${JSON.stringify(tenantData)}`);
    const tenant = new Tenant(tenantData);
    const method = 'PUT';
    const option: TGetFetchOptionParam = {method, body: tenant.json()};
    // console.log(`putTenant: url: ${getTenantUri(tenant.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getTenantUri(tenant.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
};

export const deleteTenant = async (tenantKey: TModelKey) => {
    const method = 'DELETE';
    const option: TGetFetchOptionParam = {method};
    // console.log(`deleteTenant: url: ${getTenantUri(tenantKey)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getTenantUri(tenantKey), await getFetchOption(option));
    return httpResponseCodeIsSuccess(fetchResult.status);
};
