import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";

type TRegAgentServerAttributes = {
    name?: string,
    maxSlot?: number,
    pushRate?: number,
}

export type TRegAgentServer = TBaseModel & TRegAgentServerAttributes;
export type TRegAgentServerParam = TBaseModelParam & TRegAgentServerAttributes

export class RegAgentServer extends BaseModel {
    name?: string;
    maxSlot?: number;
    pushRate?: number;

    constructor(obj: TRegAgentServerParam) {
        super(obj);
        this.name = obj.name;
        this.setAttributes(obj);
    }

    setAttributes(obj: TRegAgentServerParam) {
        this.updateAttributes(obj)
    }

    updateAttributes(obj: TRegAgentServerParam) {
        super.updateAttribute(obj, 'name');
        super.updateAttribute(obj, 'maxSlot');
        super.updateAttribute(obj, 'pushRate');
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            name: this.name,
            maxSlot: this.maxSlot,
            pushRate: this.pushRate,
            updatedAt: this.updatedAt
        }
    }
}


