import React, {useState} from "react";
import PropTypes from "prop-types";

import {Provider, TProvider} from "../../../model/provider";
import {Dialog, Button} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {FormTextField} from '../../common/FormTextField';
import Divider from "@mui/material/Divider";

export interface IProviderFormProps {
    key?: string,
    model?: Provider,
    isOpen: boolean,
    index?: number
    editMode: boolean,
    onUpdateItem: (newProvider: TProvider) => void,
    onClose: (index:number|undefined) => void
}

export const ProviderForm = (props: IProviderFormProps) => {
    const [nameProps, setNameProps] = useState<string>(props.model?.name || '');
    const [maxTenantProps, setMaxTenantProps] = useState(props.model?.maxTenant?.toString() || '10');
    const [allowTenantMaxUserProps, setAllowTenantMaxUserProps] = useState(props.model?.allowTenantMaxUser?.toString() || '50');
    const [allowTenantIdPrefixProps, setAllowTenantIdPrefixProps] = useState(props.model?.allowTenantIdPrefix?.toString() || '8');
    const [allowTenantIdRegexProps, setAllowTenantIdRegexProps] = useState(props.model?.allowTenantIdRegex?.toString() || '8[0-9]{5}');

    return (
        <Dialog key={props.key} open={props.isOpen} onClose={() => props.onClose(props.index)}>
            <DialogTitle sx={{fontWeight: 'bold'}}>{props.editMode ? 'プロパイダ編集' : 'プロパイダ登録'}</DialogTitle>
            <Divider />
            <DialogContent>
                <FormTextField id={"name"} label={"プロパイダ名"}
                               value={nameProps} setProps={setNameProps} autofocus={true}/>
                <FormTextField id={"maxTenant"} label={"最大テナント数"}
                               value={maxTenantProps} setProps={setMaxTenantProps}/>
                <FormTextField id={"allowTenantMaxUser"} label={"最大ユーザー数"}
                               value={allowTenantMaxUserProps} setProps={setAllowTenantMaxUserProps}/>
                <FormTextField id={"allowTenantIdPrefix"} label={"テナントIDプレフィックス"}
                               value={allowTenantIdPrefixProps} setProps={setAllowTenantIdPrefixProps}/>
                <FormTextField id={"allowTenantIdRegex"} label={"テナントID正規表現"}
                               value={allowTenantIdRegexProps} setProps={setAllowTenantIdRegexProps}/>
            </DialogContent>
            <DialogActions>
                <Button className="App-Form-Button" onClick={() => {
                    const provider: TProvider = {
                        parentId: props.model?.parentId || '',
                        id: props.model?.id || '',
                        // @ts-ignore
                        name: nameProps,
                        // @ts-ignore
                        maxTenant: maxTenantProps,
                        // @ts-ignore
                        allowTenantMaxUser: allowTenantMaxUserProps,
                        // @ts-ignore
                        allowTenantIdPrefix: allowTenantIdPrefixProps,
                        // @ts-ignore
                        allowTenantIdRegex: allowTenantIdRegexProps
                    };
                    props.onUpdateItem(provider);
                    props.onClose(props.index);
                }}>
                    <SaveIcon/> {props.editMode ? "更新" : "新規登録"}
                </Button>
                <Button className="App-Form-Button" onClick={() => {
                    props.onClose(props.index);
                }}><CloseIcon/>Close</Button>
            </DialogActions>
        </Dialog>
    )
};

ProviderForm.propTypes = {
    tenant: PropTypes.object,
    editMode: PropTypes.bool,
    onUpdateItem: PropTypes.func,
    onModalClose: PropTypes.func,
};