import {dset} from "dset";
// @ts-ignore
import delve from "dlv";

/**
 * IModelKey
 * @deprecated [Please use TModelKey]
 */
export interface IModelKey {
    parentId?: string;
    id?: string;
}

export type TModelKey = {
    parentId: string | undefined;
    id: string | undefined;
}

export type TModelParamKey = {
    parentId?: string | undefined;
    id?: string | undefined;
}

export type TModelPhoneticKey = {
    phoneticKey?: string;
}

/**
 * IBaseModel
 * @deprecated [Please use TBaseModel or TBaseModelParam]
 */
export interface IBaseModel extends IModelKey {
    phoneticKey?: string;
}

export type TBaseModel = TModelKey & TModelPhoneticKey;
export type TBaseModelParam = TModelParamKey & TModelPhoneticKey;

export default class BaseModel {
    parentId?: string | undefined;
    id?: string | undefined;
    protected phoneticKey?: string | undefined;
    protected updatedAt: string;

    constructor(obj: TBaseModelParam) {
        this.updatedAt = new Date().toISOString();
        this.parentId = obj.parentId
        this.id = obj.id
        if (obj.phoneticKey != null) {
            this.phoneticKey = obj.phoneticKey
        }
    }

    static transformQueryResult(queryResult: any) {
        return queryResult.LastEvaluatedKey ? {
            ...queryResult, ...{
                LastEvaluatedKey: encodeURIComponent(JSON.stringify({
                    rangeKey: queryResult.LastEvaluatedKey.rangeKey
                }))
            }
        } : queryResult;
    }

    static decodeExclusiveStartKey(encodedExclusiveStartKey: string) {
        if (encodedExclusiveStartKey) {
            const exclusiveStartKey = JSON.parse(encodedExclusiveStartKey);
            if (exclusiveStartKey) {
                return exclusiveStartKey;
            }
        }
        return null;
    }

    updateAttribute(obj: any, key: string, defaultValue?: any) {
        const value = delve(obj, key) || defaultValue;
        if (value !== undefined) {
            dset(this, key, value);
        }
    }

    get key(): TModelKey {
        return {parentId: this.parentId, id: this.id}
    }
}
