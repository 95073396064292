import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";

export type TenantAuthType = 'cognito' | 'cognito-external'

type TTenantAttributes = {
    name?: string,
    tenantId?: string,
    maxUser?: number,
    authType?: TenantAuthType,
    tenantUiSchemaId?: string,
    userUiSchemaId?: string,
    jsonTenant?: any
}

type TTenantPbsv3 = {
    pbsv3AdminEmail?: string,
    pbsv3AdminPassword?: string,
    pbsv3MaxUserCount?: string,
    pbsv3ApiHost?: string,
}

export type TTenant = TBaseModel & TTenantAttributes;
export type TTenantParam = TBaseModelParam & TTenantAttributes & TTenantPbsv3;

export class Tenant extends BaseModel {
    maxUser: number | undefined;
    tenantId: string | undefined;
    authType: TenantAuthType | undefined;
    name: string | undefined;
    tenantUiSchemaId: string | undefined;
    userUiSchemaId: string | undefined;
    jsonTenant: any;
    pbsv3AdminEmail: string | undefined;
    pbsv3AdminPassword: string | undefined;
    pbsv3MaxUserCount: string | undefined;
    pbsv3ApiHost: string | undefined;

    constructor(obj: TTenantParam) {
        super(obj);
        this.name = obj.name;
        this.maxUser = obj.maxUser;
        this.tenantId = obj.tenantId;
        this.authType = obj.authType;
        this.tenantUiSchemaId = obj.tenantUiSchemaId;
        this.userUiSchemaId = obj.userUiSchemaId;
        this.jsonTenant = obj.jsonTenant;
        // Create only
        this.pbsv3AdminEmail = obj.pbsv3AdminEmail;
        this.pbsv3AdminPassword = obj.pbsv3AdminPassword;
        this.pbsv3MaxUserCount = obj.pbsv3MaxUserCount;
        this.pbsv3ApiHost = obj.pbsv3ApiHost;
    }

    updateAttributes(obj: TTenantParam) {
        if (obj.name !== undefined) {
            this.name = obj.name;
        }
        if (obj.maxUser !== undefined) {
            this.maxUser = obj.maxUser;
        }
        if (obj.tenantUiSchemaId !== undefined) {
            this.tenantUiSchemaId = obj.tenantUiSchemaId;
        }
        if (obj.userUiSchemaId !== undefined) {
            this.userUiSchemaId = obj.userUiSchemaId;
        }
        if (obj.jsonTenant !== undefined) {
            this.jsonTenant = obj.jsonTenant;
        }
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            phoneticKey: this.phoneticKey,
            name: this.name,
            maxUser: this.maxUser,
            tenantId: this.tenantId,
            authType: this.authType,
            updatedAt: this.updatedAt,
            tenantUiSchemaId: this.tenantUiSchemaId,
            userUiSchemaId: this.userUiSchemaId,
            jsonTenant: this.jsonTenant,
            pbsv3AdminEmail: this.pbsv3AdminEmail,
            pbsv3AdminPassword: this.pbsv3AdminPassword,
            pbsv3MaxUserCount: this.pbsv3MaxUserCount,
            pbsv3ApiHost: this.pbsv3ApiHost,
        }
    }

    get url() {
        return `/providers/${this.parentId}/tenants/${this.id}`;
    }

    get templateUrl() {
        return this.url + '/tenant'
    }

    get usersUrl() {
        return this.url + '/users'
    }
}


