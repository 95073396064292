import React, {useEffect, useRef, useState} from "react";
import {Edit, Delete, Create} from '@mui/icons-material';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box,
    IconButton,
    Typography,
    Button,
    LinearProgress,
    Paper
} from '@mui/material';
import {toast} from 'react-toastify';
import {useParams} from "react-router-dom";

import GenericTemplate from "../../templates/GenericTemplate";
import {ItemsPagination} from '../../common/ItemsPagenation'
import {usePagination} from "../../../hooks/usePagination";
import {TModelKey} from "../../../model/baseModel";
import {normalizedId, normalizedText} from "../helper/formHelper";
import {RegAgentServerSlotForm} from "./RegAgentServerSlotForm";
import {
    getRegAgentServerSlotsUri,
    postRegAgentServerSlot,
    putRegAgentServerSlot,
    deleteRegAgentServerSlot
} from "../../../modules/acmv3-api/regAgentServerSlot";
import {RegAgentServerSlot, TRegAgentServerSlot} from "../../../model/regAgentServerSlot";

export const RegAgentServerSlotPage: React.FC = () => {
    const [ parentId, ] = React.useState<string>(useParams().parentId || '');
    const [ fetchUri, setFetchUri ] = useState("");
    const [ searchKeyword, ] = useState("");

    const [ currentPageItems, setCurrentPageItems ] = useState<RegAgentServerSlot[]>([])
    const openId = useRef<number>(1);

    const handleOpen = (index: number) => setDialogOpen(index, true);
    const handleClose = (index: number) => setDialogOpen(index, false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isOpens, setIsOpens ] = useState(new Array(currentPageItems.length));
    const [ isLoading, setIsLoading ] = useState(true);
    const setDialogOpen = (index: number, isOpen: boolean) => {
        const opens = new Array(currentPageItems.length);
        opens[index] = isOpen;
        setIsOpens(opens);
    };

    // Data
    const setNewUri = () => {
        setIsLoading(true);
        getRegAgentServerSlotsUri({
            parentId,
            exclusiveStartKey: keys[currentPageIndex], namePrefix: (searchKeyword || '').toLowerCase(),
        }).then(setFetchUri)
          .finally(() => setIsLoading(false))
    };

    useEffect(() => {
        setNewUri();
    }, []);

    const {
        currentPageIndex,
        setPagerNext,
        setPagerPrevious,
        setPagerChange,
        pageActionAfterRemoveItem,
        totalPages,
        keys
    } = usePagination({
        fetchUri, setCurrentPageItems,
        onError: e => {
            toast.error(`一覧情報の読み込みに失敗しました :${e.toString()}`);
        },
        onNewUri: setNewUri
    })

    const onCreateData = (newData: TRegAgentServerSlot) => {
        postRegAgentServerSlot({parentId, item: newData}).then(r => {
            const newItems = [
                ...currentPageItems,
                new RegAgentServerSlot(r.body)
            ];
            setCurrentPageItems(newItems);
        });
    }

    const onUpdateData = (newItem: TRegAgentServerSlot) => {
        putRegAgentServerSlot(newItem).then(() => {
            toast.success('更新に成功しました');
            setCurrentPageItems(currentPageItems.map(item => {
                    return item.parentId === newItem.parentId && item.id === newItem.id ?
                        new RegAgentServerSlot(newItem) : item
                }
            ));
        }).catch(e => {
            toast.error(`更新に失敗しました + ${e.toString()}`);
        });
        const newItems = currentPageItems.map(item => item.id === newItem.id ? new RegAgentServerSlot(newItem) : item);
        setCurrentPageItems(newItems);
    }

    const removeItemHandler = (regAgentServerId: string|undefined, slotId: string|undefined) => {
        // @ts-ignore
        deleteRegAgentServerSlot(regAgentServerId, slotId).then(() => {
            toast.success('削除に成功しました');
            setCurrentPageItems(currentPageItems.filter(model => model.id !== slotId));
            pageActionAfterRemoveItem(currentPageItems.length);
        }).catch((e: any) => {
            toast.error(`削除に失敗しました + ${e.toString()}`);
        })
    }

    const setClose = () => setIsOpen(false);

    const TableProvider = (props: { items: RegAgentServerSlot[] }) => {
        return (
            <TableContainer component={Paper}>
                <div className="App-Content-Menu">
                    <Box>
                        <Button onClick={() => {
                            setIsOpen(true);
                            openId.current = openId.current + 1;
                        }}><Create/>新規作成</Button>
                    </Box>
                </div>

                <RegAgentServerSlotForm
                    key={openId.current.toString()}
                    isOpen={isOpen}
                    onClose={setClose}
                    onUpdateItem={onCreateData}
                    editMode={false}
                    model={new RegAgentServerSlot({name: ''})}
                />

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>スロット名</TableCell>
                            <TableCell align="left">Parent</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="right">編集</TableCell>
                            <TableCell align="right">削除</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items.map((model: RegAgentServerSlot, index: number) => {
                            //console.log(`props.items`, model.id)
                            return (
                                <TableRow key={model.id}>
                                    <TableCell component="th" scope="row">{normalizedText(model.name)}</TableCell>
                                    <TableCell align="left">{normalizedId(model.parentId)}</TableCell>
                                    <TableCell align="left">{normalizedId(model.id)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton onClick={() => handleOpen(index)}>
                                            <Edit/>
                                        </IconButton>
                                        <RegAgentServerSlotForm
                                            key={model.id}
                                            model={model}
                                            isOpen={isOpens[index]}
                                            index={index}
                                            onClose={() => handleClose(index)}
                                            onUpdateItem={onUpdateData}
                                            editMode={true}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => removeItemHandler(model.parentId, model.id)}>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <GenericTemplate title="+Push RegAgentサーバー一覧">
            <Typography variant="h5" sx={{m: "1rem"}}>
                +Push RegAgentサーバースロット一覧
            </Typography>
            {isLoading ? (<LinearProgress/>) : (<TableProvider items={currentPageItems}/>)}
            <Box sx={{m: "1rem"}}>
                <ItemsPagination itemCount={currentPageItems.length}
                                 currentPageNo={currentPageIndex + 1}
                                 totalPages={totalPages}
                                 onNext={setPagerNext}
                                 onPrevious={setPagerPrevious}
                                 onChange={setPagerChange}/>
            </Box>
        </GenericTemplate>
    );

};
