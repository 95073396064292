import Amplify, {Auth} from 'aws-amplify';
import {Provider} from "../model/provider"
import {useState, useEffect} from "react";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const httpResponseCodeIsSuccess = httpCode => 200 <= httpCode && httpCode <= 299;

export const awsConfig = {
    Auth: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION || 'ap-northeast-1',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
    }
}

Amplify.configure(awsConfig);

export const getFetchOption = async ({method = 'GET', body} = {}) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const options = {
        method,
        mode: 'cors',
        headers: {
            Authorization: token,
            'Content-Type': 'application/json'
        }
    };
    if (['POST', 'PATCH', 'PUT'].includes(method)) {
        if (typeof body === 'string') {
            options.body = body;
        } else {
            options.body = JSON.stringify(body);
        }
    }

    return options;
};

export function useGetTenantsFetch({uri, fetchOption} = {}) {
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!uri) return;
        fetch(uri, fetchOption)
            .then(data => data.json())
            .then(setData)
            .then(() => setIsLoading(false))
            .catch(setError);
    }, [uri, fetchOption]);

    return {
        isLoading,
        data,
        error
    };
}


//TODO: Check user grant
const parseProviderParentId = parentId => parentId ? parentId : 'PROVIDER';

export const getProvidersUri = async ({parentId, exclusiveStartKey, namePrefix} = {}) => {
    if (!parentId) {
        const user = await Auth.currentAuthenticatedUser();
        parentId = user.attributes['custom:providerId'];
    }
    let uri = `${API_BASE_URL}/providers/${parentId}?perPage=15`;
    if (exclusiveStartKey) {
        uri += `&exclusiveStartKey=${exclusiveStartKey}`;
    }
    if (namePrefix) {
        uri += `&name=${namePrefix}`;
    }
    console.log({uri, API_BASE_URL, 'env': process.env.REACT_APP_API_BASE_URL})
    return uri;
}
const getProviderUri = ({parentId, id}) => {
    return `${API_BASE_URL}/providers/${parseProviderParentId(parentId)}/child/${id}`;
};

export const getProviders = async ({exclusiveStartKey, namePrefix}) => {
    const url = await getProvidersUri({exclusiveStartKey, namePrefix});
    const res = await fetch(url, await getFetchOption());
    const json = await res.json();
    console.log(json)
    return json;
};

export const getProvider = async ({parentId, id}) => {
    const res = await fetch(getProviderUri({parentId, id}), await getFetchOption());
    const json = await res.json();
    console.log(json)
    return json;
};

export const postProvider = async ({parentId, createItem}) => {
    const provider = new Provider({...createItem, ...{id: ''}});
    const method = 'POST';
    const fetchResult = await fetch(await getProvidersUri({parentId}),
        await getFetchOption({method, body: provider.json()}));
    const body = await fetchResult.json();
    console.log('postProvider fetch...')
    console.log(provider.json())
    console.log(body)

    return {body, fetchResult};
};

export const putProvider = async (item) => {
    const provider = new Provider(item);
    const method = 'PUT';
    const option = {method, body: provider.json()};
    console.log(`putProvider: url: ${getProviderUri(provider.key)} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getProviderUri(provider.key), await getFetchOption(option));

    return {body: await fetchResult.json(), fetchResult};
};

export const deleteProvider = async ({parentId, id}) => {
    const method = 'DELETE';
    const option = {method};
    console.log(`deleteProvider: url: ${{parentId, id}} option: ${JSON.stringify(option)}`);

    const fetchResult = await fetch(getProviderUri({parentId, id}), await getFetchOption(option));
    console.log(fetchResult)
    return httpResponseCodeIsSuccess(fetchResult.status);
};
