import React, {useState, useEffect, useRef} from "react";
import {MuiForm5 as Form} from '@rjsf/material-ui';
import {JsonEditor} from '../../elements/JsonEditor'; //"../elements/JsonEditor";

import {Grid, Typography} from "@mui/material";
import {setJsonEditorRef} from "../../../hooks/refJsonEditor";
import GenericTemplate from "../../templates/GenericTemplate";

const isValidJson = (value: any) => {
    try {
        if (!value) return false;
        if (typeof value !== 'object') {
            JSON.parse(value)
        }
        return true;
    } catch (error) {
        return false;
    }
}

const loadJSON = (key: string) => {
    let value;
    try {
        value = window.localStorage.getItem(key);
        console.log({event: 'loadJSON', key, value})
        if (typeof value !== 'object') {
            return JSON.parse(value);
        } else {
            return value;
        }
    } catch (error) {
        console.error("loadJSON error", error)
    }
}

const saveJsonString = (key: string, data: string) => {
    try {
        window.localStorage.setItem(key, data);
        console.log({event: 'saveJSON', key, data})
    } catch (error) {
        console.log("saveJSON error", error)
    }
}

const isPresent = (json: string) => {
    return Object.keys(JSON.parse(json)).length > 0
}

export const JsonEditorPage: React.FC = () => {
    const [jsonSchemaProps, setJsonSchemaProps] = useState({});
    const [uiSchemaProps, setUiSchemaProps] = useState({});
    const [editorDataProps, setEditorDataProps] = useState({});
    const [formDataProps, setFormDataProps] = useState({});
    const [focusEditor, setFocusEditor] = useState<string>();
    // const containerRef = useRef<HTMLDivElement | null>(null);

    const onSubmitJson = (eventData: any) => {
        console.log('onSubmitJson', eventData);
    }

    const onFocusEditor = (name: string) => {
        setFocusEditor(name);
    }

    const onSchemaEditorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`onSchemaEditorChange this:${this}`, e);
        if (isValidJson(e)) {
            setJsonSchemaProps(e)
            saveJsonStringWrapper(`jsonSchemaProps`, JSON.stringify(e));
        } else {
            console.log('onSchemaEditorChange invalid json', e);
        }
    }

    const onUiSchemaEditorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('onUiSchemaEditorChange', e);
        if (isValidJson(e)) {
            setUiSchemaProps(e);
            saveJsonStringWrapper('uiSchemaProps', JSON.stringify(e))
        }
    }

    const onChangeEditorData = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('onChangeEditorData', e);
        if (isValidJson(e)) {
            setEditorDataProps(e);
            setFormDataProps(e);
            saveJsonStringWrapper('formDataProps', JSON.stringify(e))
        }
    }

    const onChangeFormData = (e: any) => {
        console.log('onChangeFormData', e);
        if (isValidJson(e)) {
            setFormDataProps(e.formData);
            setEditorDataProps(e.formData);
            saveJsonStringWrapper('formDataProps', JSON.stringify(e.formData))
        }
    }

    const saveJsonStringWrapper = (key: string, jsonString: string) => {
        console.log(`saveJsonStringWrapper key: ${key}`, jsonString)
        if (isPresent(jsonString)) {
            saveJsonString(key, jsonString)
        } else {
            console.log(`not save`, key)
        }
    }

    const jsonSchemaEditorRef = useRef(null);
    const setJsonSchemaEditorRef = setJsonEditorRef(jsonSchemaEditorRef);
    useEffect(() => {
            //すでにFocusの場合は実施しない。
            if (jsonSchemaEditorRef.current !== null && focusEditor !== 'jsonSchema') {
                // @ts-ignore
                jsonSchemaEditorRef.current.set(jsonSchemaProps);
            }
        },[focusEditor, jsonSchemaProps]);

    const uiSchemaEditorRef = useRef(null);
    const setUiSchemaEditorRef = setJsonEditorRef(uiSchemaEditorRef)
    useEffect(() => {
        if (uiSchemaEditorRef.current !== null && focusEditor !== 'uiSchema') {
            // @ts-ignore
            uiSchemaEditorRef.current.set(uiSchemaProps);
        }
    }, [focusEditor, uiSchemaProps]);

    const formDataEditorRef = useRef(null);
    const setEditorDataRef = setJsonEditorRef(formDataEditorRef);
    useEffect(() => {
        if (formDataEditorRef.current !== null && focusEditor !== 'formData') {
            // @ts-ignore
            formDataEditorRef.current.set(formDataProps);
        }
    }, [focusEditor, formDataProps]);

    // load from local storage
    useEffect(() => {
        const jsonSchema = loadJSON('jsonSchemaProps')
        setJsonSchemaProps(jsonSchema);

        const uiSchema = loadJSON('uiSchemaProps');
        uiSchema && setUiSchemaProps(uiSchema);

        const formDataProps = loadJSON('formDataProps');
        formDataProps && setFormDataProps(formDataProps);

        console.log({
            event: 'load from local storage',
            jsonSchema, uiSchemaProps: uiSchema, formDataProps
        })
    }, [])

    return (
        <GenericTemplate title="JSONテンプレート">
            {/*<SplitPane split="vertical">*/}
            <Grid container spacing={4} alignItems="stretch">
                <Grid item xl={3}>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <Typography>JSON Schema</Typography>
                    <JsonEditor
                        name={'Schema'}
                        value={jsonSchemaProps}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSchemaEditorChange(e)}
                        onFocus={() => onFocusEditor('jsonSchema')}
                        setEditorRef={setJsonSchemaEditorRef}
                    />
                </Grid>
                <Grid item xl={3}>
                    <Typography>UI Schema</Typography>
                    <JsonEditor
                        name={'UI Schema'}
                        value={uiSchemaProps}
                        onChange={onUiSchemaEditorChange}
                        onFocus={() => onFocusEditor('uiSchema')}
                        setEditorRef={setUiSchemaEditorRef}
                    />
                </Grid>
                <Grid item xl={3}>
                    <Typography>Form data</Typography>
                    <JsonEditor
                        name={'Form data'}
                        value={editorDataProps}
                        onChange={onChangeEditorData}
                        onFocus={() => onFocusEditor('formData')}
                        setEditorRef={setEditorDataRef}
                    />
                </Grid>

                <Grid item xl={3}>
                    <Form
                        schema={jsonSchemaProps}
                        uiSchema={uiSchemaProps}
                        formData={formDataProps}
                        onChange={onChangeFormData}
                        onSubmit={onSubmitJson}
                    />

                </Grid>

            </Grid>

        </GenericTemplate>

    )

}