import BaseModel, {TBaseModel, TBaseModelParam} from "./baseModel";

type TRegAgentServerSlotAttributes = {
    name?: string,
    tenantId?: string,
    slotNo?: number
    maxUser?: number,
    pushRate?: number,
}

export type TRegAgentServerSlot = TBaseModel & TRegAgentServerSlotAttributes;
export type TRegAgentServerSlotParam = TBaseModelParam & TRegAgentServerSlotAttributes

export class RegAgentServerSlot extends BaseModel {
    name?: string;
    tenantId?: string;
    slotNo?: number;
    maxUser?: number;
    pushRate?: number;

    constructor(obj: TRegAgentServerSlotParam) {
        super(obj);
        this.name = obj.name;
        this.setAttributes(obj);
    }

    setAttributes(obj: TRegAgentServerSlotParam) {
        this.updateAttributes(obj)
    }

    updateAttributes(obj: TRegAgentServerSlotParam) {
        super.updateAttribute(obj, 'name');
        super.updateAttribute(obj, 'tenantId');
        super.updateAttribute(obj, 'slotNo');
        super.updateAttribute(obj, 'maxUser');
        super.updateAttribute(obj, 'pushRate');
    }

    json() {
        return {
            parentId: this.parentId,
            id: this.id,
            name: this.name,
            tenantId: this.tenantId,
            slotNo: this.slotNo,
            maxUser: this.maxUser,
            pushRate: this.pushRate,
            updatedAt: this.updatedAt
        }
    }
}


