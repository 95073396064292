import {Dialog, Link} from "@mui/material";
import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

export interface UserQrDialogResultProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    url: string
}

export const UserQrResultDialog: React.FC<UserQrDialogResultProps> = (props) => {
    return (
        <Dialog maxWidth={'md'} open={props.isOpen} onClose={() => {
            props.setIsOpen(false)
        }} scroll="body">
            <DialogTitle>QR コード ダウンロード</DialogTitle>
            <DialogContent>
                <Link href={props.url} underline="hover">
                  ダウンロード
                </Link>
            </DialogContent>
        </Dialog>
    );
}
